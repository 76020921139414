<template>
    <div id="app">
        <b-navbar variant="light" class="border-bottom d-flex">
            <b-navbar-brand>
                <b-img @click="restartSteps" class="newsLogo" src="https://newsltd.service-now.com/NewsCorp_Australia-Logo.png" />
            </b-navbar-brand>

            <b-navbar-brand class="ml-auto" style="font-weight:600;">
                Catalogue Connect | Feed Builder <span style="font-weight:normal;font-size:12px;"><b-badge variant="danger">Beta</b-badge></span>
            </b-navbar-brand>
        </b-navbar>
        <div>
            <b-progress :max="5" v-if="$store.state.feedType == 'standard'">
                <b-progress-bar :value="$store.state.step">
                    <div>
                        <strong>
                            <span v-if="$store.state.step > 0">Step {{ $store.state.step }} / 5: </span>
                            <span v-if="$store.state.step == 1">Create or Edit</span>
                            <span v-if="$store.state.step == 2">Input</span>
                            <span v-else-if="$store.state.step == 3">Build</span>
                            <span v-else-if="$store.state.step == 4">Review</span>
                            <span v-else-if="$store.state.step == 5">Download</span>
                        </strong>
                    </div>
                </b-progress-bar>
            </b-progress>
            <b-progress v-else-if="$store.state.feedType == 'amazon'" :max="4">
                <b-progress-bar :value="$store.state.step">
                    <div>
                        <strong>
                            <span v-if="$store.state.step > 0"> Step {{ $store.state.step }} / 4: </span>
                            <span v-if="$store.state.step == 1">Create or Edit</span>
                            <span v-else-if="$store.state.step == 2">Build</span>
                            <span v-else-if="$store.state.step == 3">Review</span>
                            <span v-else-if="$store.state.step == 4">Final</span>
                        </strong>
                    </div>
                </b-progress-bar>
            </b-progress>
            <b-progress v-else :max="100">
                <b-progress-bar :value="0"></b-progress-bar>
            </b-progress>
        </div>
        <b-container v-if="$store.state.userProfile == null">
            <div class="mt-4 text-center">
                <b-spinner />
            </div>
        </b-container>
        <b-container v-else-if="$store.state.userProfile.loggedIn && !$store.state.userProfile.data.emailVerified">
            <div class="mt-4">
                <VerifyEmail />
            </div>
        </b-container>
        <b-container v-else-if="$store.state.userProfile.loggedIn && $store.state.userProfile.data.emailVerified">
            <div class="mt-4 mb-5">
                <!-- <StepZero v-if="step == 0" @gmcNextStepOne="gmcNextStepOne" @amazonNextStepOne="amazonNextStepOne" />
                <GMCMain v-if="feedType == 'gmc'" :step="step" @gmcNextStepTwo="gmcNextStepTwo" @gmcNextStepThree="gmcNextStepThree" @gmcNextStepFour="gmcNextStepFour" @gmcPrevStepTwo="gmcPrevStepTwo" />
                <AmazonMain v-else-if="feedType == 'amazon'" :step="step" @amazonNextStepTwo="amazonNextStepTwo" @amazonPrevStepTwo="amazonPrevStepTwo" @amazonNextStepThree="amazonNextStepThree" @amazonNextStepFour="amazonNextStepFour" /> -->
                <router-view />
            </div>

            <div class="mt-3 text-muted font-small text-center mb-5">
                You are signed in as {{ $store.state.userProfile.data.email }}. Click <b-link href="#" @click="signOut">here</b-link> to sign out.
            </div>
        </b-container>
        <b-container v-else>
            <div class="mt-5">
                <Login />
            </div>
        </b-container>
    </div>
</template>

<script>
import Login from '@/components/Auth/Login.vue'
import VerifyEmail from '@/components/Auth/VerifyEmail.vue'

import { auth } from '@/firebase'

export default {     
    name: 'App',
    components: { Login, VerifyEmail },
    methods: {
        restartSteps: function() {
            if (this.$store.state.step !== 0) {
                this.$bvModal.msgBoxConfirm('Are you sure you want to go back to start? All progress will be lost.', {
                    title: 'Please Confirm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'I\'m Sure',
                    cancelTitle: 'Cancel',
                    centered: true,
                    footerClass: 'p-2'
                })
                .then(value => {
                    if (value) {
                        this.$store.commit("setAmazonFeed", null);
                        this.$store.commit("setLoadedAmazonProducts", []);
                        this.$store.commit("setStandardFeed", null);
                        this.$store.commit("setStandardLoadedProducts", []);
                        this.$store.commit("resetAmazonChangelog");
                        this.$store.commit("resetStandardChangelog");

                        this.$router.push("/");
                    }
                })
                .catch(e => {
                    console.error(e);
                })
            }
        },

        signOut: function() {
            this.$bvModal.msgBoxConfirm('Are you sure you want to sign out? All unsaved progress will be lost.', {
                title: 'Please Confirm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sign Out',
                cancelTitle: 'Go Back',
                centered: true,
                footerClass: 'p-2'
            })
            .then(async value => {
                if (value) {
                    this.step = 0;
                    this.feedType = '';
                    await auth.signOut();
                    this.$router.push("/");
                }
            })
        }
    }
}
</script>

<style scoped>
    .newsLogo:hover {
        cursor: pointer;
    }

    .font-small {
        font-size: 12px;
    }
</style>