<template>
    <!-- 
        Standard New to the URL: https://feedbuilder.newsconnect.com.au/feed/standard/new
        Allows users to upload a Google Merchant Center feed, or alternatively progress with an empty feed.
     -->
    <b-row>
        <b-col lg="3" cols="0">

        </b-col>
        <b-col lg="6" cols="12">
            <b-container>
                <b-card no-body>
                    <b-card-body>
                        <b-card-title>New Feed</b-card-title>
                        <div class="stepOneCont">
                            <b-card-sub-title>First step, upload Google Merchant Center feed, or start an empty one:</b-card-sub-title>
                            <div class="text-center">
                                <div>
                                    <GMCFeedURL class="mt-2 px-2 text-left" @feedAdded="nextStepTwo" />
                                </div>
                                <div class="mt-4">OR</div>
                                <div>
                                    <GMCInputForm class="mt-2 px-2 text-left" @feedAdded="nextStepTwo" />
                                </div>
                            </div>
                                
                        </div>
                    </b-card-body>
                </b-card>

                <FeedLayoutComponent class="mt-5" />
            </b-container>
        </b-col>    
        <b-col lg="3" cols="0">

        </b-col>
    </b-row>
</template>

<script>
import FeedLayoutComponent from '@/components/GoogleMerchantCenter/FeedLayoutComponent.vue'
import GMCInputForm from '@/components/GoogleMerchantCenter/GMCInputForm.vue'
import GMCFeedURL from '../../components/GoogleMerchantCenter/GMCFeedURL.vue';


export default {
    name: 'StandardAPINew',
    components: { FeedLayoutComponent, GMCInputForm, GMCFeedURL },

    mounted() {
        // Check that we have a feed in the store (and that we haven't just loaded here.)
        // Otherwise go back to the home.
        if (this.$store.state.standardFeed === null) {
            this.$router.replace({path: "/feed/standard", query: this.$route.query});
            return;
        }

        if (this.$route.query.ref === "newmanualfeed") {
            this.$router.replace("/feed/standard/build");
        }
    },

    methods: {
        nextStepTwo() {
            this.$router.push("/feed/standard/build");
        },
    }
}
</script>