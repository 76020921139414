import Vue from 'vue'
import VueRouter from 'vue-router'
import { getCurrentUser } from '@/firebase'

Vue.use(VueRouter);

import store from '@/store'

import Home from '@/views/Home'

// AMAZON
import AmazonHome from '@/views/Amazon/AmazonHome'
import AmazonBuild from '@/views/Amazon/AmazonBuild'
import AmazonReview from '@/views/Amazon/AmazonReview'
import AmazonPreview from '@/views/Amazon/AmazonPreview'

// GOOGLE MERCHANT CENTER
import StandardHome from "@/views/Standard/StandardHome"
import StandardNew from '@/views/Standard/StandardNew'
import StandardBuild from '@/views/Standard/StandardBuild'
import StandardReview from '@/views/Standard/StandardReview'
import StandardPreview from '@/views/Standard/StandardPreview'

// GOOGLE MERCHANT CENTER API
import StandardAPIHome from "@/views/StandardAPI/StandardAPIHome"
import StandardAPINew from '@/views/StandardAPI/StandardAPINew'
import StandardAPIBuild from '@/views/StandardAPI/StandardAPIBuild'
import StandardAPIReview from '@/views/StandardAPI/StandardAPIReview'
import StandardAPIPreview from '@/views/StandardAPI/StandardAPIPreview'

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            feedType: '',
            step: 0
        }
    },
    // AMAZON
    {
        path: "/feed/amazon",
        name: "AmazonHome",
        component: AmazonHome,
        meta: {
            requiresAuth: true,
            feedType: 'amazon',
            step: 1
        }
    },
    {
        path: "/feed/amazon/build",
        name: "AmazonBuild",
        component: AmazonBuild,
        meta: {
            requiresAuth: true,
            feedType: 'amazon',
            step: 2
        }
    },
    {
        path: "/feed/amazon/review",
        name: "AmazonReview",
        component: AmazonReview,
        meta: {
            requiresAuth: true,
            feedType: 'amazon',
            step: 3
        }
    },
    {
        path: "/feed/amazon/preview",
        name: "AmazonPreview",
        component: AmazonPreview,
        meta: {
            requiresAuth: true,
            feedType: 'amazon',
            step: 4
        }
    },
    // GOOGLE MERCHANT CENTER
    {
        path: "/feed/standard",
        name: "StandardHome",
        component: StandardHome,
        meta: {
            requiresAuth: true,
            feedType: 'standard',
            step: 1
        }
    },
    {
        path: "/feed/standard/new",
        name: "StandardNew",
        component: StandardNew,
        meta: {
            requiresAuth: true,
            feedType: 'standard',
            step: 2
        }
    },
    {
        path: "/feed/standard/build",
        name: "StandardBuild",
        component: StandardBuild,
        meta: {
            requiresAuth: true,
            feedType: 'standard',
            step: 3
        }
    },
    {
        path: "/feed/standard/review",
        name: "StandardReview",
        component: StandardReview,
        meta: {
            requiresAuth: true,
            feedType: 'standard',
            step: 4
        }
    },
    {
        path: "/feed/standard/preview",
        name: "StandardPreview",
        component: StandardPreview,
        meta: {
            requiresAuth: true,
            feedType: 'standard',
            step: 5
        }
    },
    //GOOGLE MERCHANT CENTER CUSTOM API
    {
        path: "/feed/standardAPI",
        name: "StandardHomeAPI",
        component: StandardAPIHome,
        meta: {
            requiresAuth: true,
            feedType: 'standardAPI',
            step: 1
        }
    },
    {
        path: "/feed/standardAPI/new",
        name: "StandardAPINew",
        component: StandardAPINew,
        meta: {
            requiresAuth: true,
            feedType: 'standardAPI',
            step: 2
        }
    },
    {
        path: "/feed/standardAPI/build",
        name: "StandardAPIBuild",
        component: StandardAPIBuild,
        meta: {
            requiresAuth: true,
            feedType: 'standardAPI',
            step: 3
        }
    },
    {
        path: "/feed/standardAPI/review",
        name: "StandardReviewAPI",
        component: StandardAPIReview,
        meta: {
            requiresAuth: true,
            feedType: 'standardAPI',
            step: 4
        }
    },
    {
        path: "/feed/standardAPI/preview",
        name: "StandardAPIPreview",
        component: StandardAPIPreview,
        meta: {
            requiresAuth: true,
            feedType: 'standardAPI',
            step: 5
        }
    }
]

const router = new VueRouter({
    mode: "history",
    routes
});

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(x => x.meta.requiresAuth);

    if (requiresAuth && !await getCurrentUser()) {
        next("/");
        return;
    }

    store.commit("setFeedTypeAndStep", [to.meta.feedType, to.meta.step]);
    
    next();
})

export default router;