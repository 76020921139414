<template>
    <!-- 
        Standard Preview points to the URL: https://feedbuilder.newsconnect.com.au/feed/standard/preview
     -->
    <b-row>
        <b-col cols="0" lg="3">

        </b-col>
        <b-col cols="12" lg="6">
            <b-card no-body>
                <b-card-body>
                    <b-card-title>Feed uploaded successfully!</b-card-title>
                    <b-card-sub-title>Feed ID: {{ $store.state.standardFeed.uid }}</b-card-sub-title>

                    <div class="mt-2">
                        <p>Save the above feed ID and pass on to your account manager.</p>
                        <p>In the meantime, please see a preview of the unit below. You can upload a background image to see what your final unit may look like.</p>
                        <p>Note: Changes to the background image will not be uploaded/reflected in your live unit.</p>
                    </div>

                </b-card-body>
            </b-card>

            <b-card no-body class="mt-5">
                <b-card-body>
                    <b-card-title>Preview</b-card-title>

                    <div>
                        <label for="backgroundUpload">Background Image (300x600)</label>
                        <b-form-file id="backgroundUpload" v-model="backgroundImageFile" @change="buildBackgroundURL" />
                    </div>
                    

                    <CatalogueComponent class="mt-2" :feed="$store.state.standardFeed.products" :backgroundImage="backgroundImageString" />
                </b-card-body>
            </b-card>

        </b-col>
        <b-col cols="0" lg="3">

        </b-col>

    </b-row>
</template>

<script>
import CatalogueComponent from '@/components/GoogleMerchantCenter/CatalogueComponent.vue'

export default {
    name: 'StandardAPIPreview',
    components: { CatalogueComponent },

    data() {
        return {
            backgroundImageFile: null,
            backgroundImageString: '',
        }
    },

    created() {
        if (!this.$store.state.standardFeed || this.$store.state.standardFeed.products.length == 0) {
            this.$router.replace("/feed/standard");
        }
    },

    methods: {
        buildBackgroundURL(event) {
            this.backgroundImageString = URL.createObjectURL(event.target.files[0]);
        },
    }
}
</script>