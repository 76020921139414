<template>
    <b-form @submit.prevent="buildProducts" class="d-flex flex-column">
        <label for="urlInput">Enter Google Merchant Center API URL:</label>
        <div id="fileInputCont">
            <b-form-input id="urlInput" class="urlInput" v-model="urlFeed"></b-form-input>
        </div>

        <div class="buttons mt-2 mx-auto">
            <b-button variant="outline-dark" size="sm" @click="clearInputs" :disabled="isBuilding">Clear</b-button>
            <b-button variant="outline-success" size="sm" class="ml-2" type="submit">
                <span v-if="!isBuilding">Add Products</span>
                <span v-else><b-spinner small /></span>
            </b-button>
        </div>
    </b-form>
</template>

<script>
import parser from 'xml2js'
import { functions } from '@/firebase'

export default {
    name: 'GMCFeedURL',
    // props: {
    //     isNew: {
    //         type: Boolean,
    //         required: true
    //     }
    // },
    data() {
        return {
            fileInput: null,
            urlFeed: null,
            isBuilding: false,
            // Will only read the below tags from the input!
            acceptedTags: ["id", "title", "link", "price", "image_link", "sale_price", "brand", "google_product_category", "product_type", "availability", "condition"]
        }
    },

    methods: {
        buildProducts: function() {
            this.isBuilding = true;
            this.urlAdd(this.urlFeed);

        },

        clearInputs: function() {
            this.urlFeed = null;
        },

        async urlAdd(urlFeed){ 
            try{
                const callCustomAPI = functions.httpsCallable('callCustomAPI');
                const result = await callCustomAPI({ url: urlFeed }).catch(() => {
                    return null;
                });

                const xmlResult = result.data;
                const data = await parser.parseStringPromise(xmlResult, {
                    explicitArray: false,
                    ignoreAttrs: true,
                    tagNameProcessors: [(name) => {
                        if(name.substring(0, 2) == "g:") {
                            return name.substring(2, name.length);
                        } else {
                            return name;
                        }
                    }],
                    valueProcessors: [(value, name) => {
                        // If any value surrounded by quotation marks, remove them.
                        if(value && value.charAt(0) == "\"" && value.charAt(value.length - 1) == "\""){
                            value = value.substring(1, value.length - 1);
                        }
                        // Remove AUD from price and sale_price
                        if(value && (name == "price" || name == "sale_price")){
                            if (value.substring(value.length - 4, value.length) == " AUD") {
                                value = value.substring(0, value.length - 4);
                            } else if (value.substring(value.length - 3, value.length) == "AUD") {
                                value = value.substring(0, value.length - 3);
                            }
                        }
                        if(value && name == "image_link"){
                            if(value.substring(0,5) == "http:"){
                                value = "https" + value.substring(4, value.length);
                            }
                        }
                        return value;
                    }]
                });

                let feed = data.rss.channel.item;
                
                for (let i = 0; i < feed.length; i ++) {
                    const temp = feed[i];

                    feed[i] = {
                        id: temp.id,
                        title: temp.title,
                        link: temp.link,
                        price: temp.price,
                        image_link: temp.image_link
                    }

                    if (temp.sale_price) {
                        feed[i].sale_price = temp.sale_price;
                    }

                    if (temp.brand) {
                        feed[i].brand = temp.brand;
                    }

                    if (temp.google_product_category) {
                        feed[i].google_product_category = temp.google_product_category;
                    }   
                    
                    if (temp.product_type) {
                        feed[i].product_type = temp.product_type;
                    }

                    if (temp.availability) {
                        feed[i].availability = temp.availability;
                    }

                    if (temp.condition) {
                        feed[i].condition = temp.condition;
                    }
                }
                this.$store.commit('setFeedType', 'standardAPI');
                this.$store.commit('setURL', urlFeed);
                this.$store.commit("addStandardFeedToLoaded", feed);
                this.$emit("feedAdded");
            }
            catch (err) {
                // TODO: Add better error handling.
                console.error("Error loading data", err);
            }
        },
        readFileAsText(file) {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                reader.readAsText(file);
            })
        },
    }
}
</script>

<style>
#fileInputCont .custom-file-label:hover {
    cursor: pointer;
}
</style>
