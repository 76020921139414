<template>
    <b-card
        :img-src="product.image_link"
        img-top
        :title="product.title"
    >
        <b-card-sub-title>{{ product.brand }}</b-card-sub-title>
        <b-card-text class="mt-2">
            <div>
                <strong>RRP</strong>: ${{ product.price }}
            </div>
            <div v-if="product.sale_price && product.sale_price < product.price">
                <strong>Sale Price</strong>: ${{ product.sale_price }}
            </div>
            <!-- <div v-html="product.description"></div> -->
        </b-card-text>

        <div class="text-center">
            <b-button variant="outline-success" size="sm" @click="addProduct" v-if="!selected">Add Product</b-button>
            <b-button variant="outline-dark" size="sm" @click="startEdit" v-if="selected" class="mr-1">Edit</b-button>
            <b-button variant="outline-danger" size="sm" @click="removeProduct" v-if="selected" class="ml-1">Remove</b-button>
        </div>

        <b-modal centered ok-variant="success" button-size="sm" v-model="isEditing" @cancel="cancelEdit" @hide="cancelEdit" @hidden="cancelEdit">
            <template #modal-header>
                <div>
                    <div><h5>Edit Product: {{ product.id }}</h5></div>
                </div>
            </template>

            <b-img :src="newProductData.image_link" class="w-100" />

            <div class="mt-2">
                <div>
                    <div><h5>Image</h5></div>
                    <div>
                        <b-form-group label="Image Link:">
                            <b-form-input type="text" v-model="newProductData.image_link" lazy size="sm" />
                        </b-form-group>
                    </div>
                </div>

                <div class="mt-3">
                    <div><h5>Product Title</h5></div>
                    <div>
                        <b-form-group label="Title:">
                            <b-form-input type="text" v-model="newProductData.title" size="sm" />
                        </b-form-group>
                    </div>
                </div>

                <div class="mt-3">
                    <div><h5>Brand</h5></div>
                    <div>
                        <b-form-group label="Brand (optional):">
                            <b-form-input type="text" v-model="newProductData.brand" size="sm" />
                        </b-form-group>
                    </div>
                </div>

                <div class="mt-3">
                    <div><h5>Price</h5></div>
                    <div>
                        <b-form-group label="Price (RRP):">
                            <b-form-input type="number" v-model="newProductData.price" size="sm" />
                        </b-form-group>

                        <b-form-group label="Sale Price (less than RRP, optional):">
                            <b-form-input type="number" v-model="newProductData.sale_price" size="sm" />
                        </b-form-group>
                    </div>
                </div>

                <div class="mt-3">
                    <h5>Availability</h5>
                    <div class="text-center mt-2">
                        <b-form-radio-group button-variant="outline-primary" v-model="newProductData.availability" :options="availabilityOptions" buttons size="sm" />
                    </div>
                </div>

                <div class="mt-3">
                    <h5>Clickthrough URL</h5>
                    <b-form-group label="URL:">
                        <b-form-input placeholder="URL" v-model="newProductData.link" size="sm" />
                    </b-form-group>
                </div>
            </div>
            <template #modal-footer>
                <div class="text-center mt-2">
                    <b-button variant="outline-danger" size="sm" @click="cancelEdit" class="mr-1">Cancel</b-button>
                    <b-button variant="outline-dark" size="sm" @click="finishEdit" class="ml-1" :disabled="!editedIsValid">Update</b-button>
                </div>
            </template>
        </b-modal>
    </b-card>


    <!-- <b-card v-else no-body :img-src="newProductData.image_link" img-top>
        <b-card-body>
            <div>
                <b-form>
                    <b-form-group description="Image Link">
                        <b-form-input placeholder="Image Link" v-model="newProductData.image_link"></b-form-input>
                    </b-form-group>

                    <b-form-group description="Product Title">
                        <b-form-input placeholder="Product Title" v-model="newProductData.title"></b-form-input>
                    </b-form-group>

                    <b-form-group description="Brand">
                        <b-form-input placeholder="Product Brand" v-model="newProductData.brand"></b-form-input>
                    </b-form-group>

                    <b-form-group description="Price (RRP)">
                        <b-form-input placeholder="Price" v-model="newProductData.price"></b-form-input>
                    </b-form-group>

                    <b-form-group description="Sale Price (less than RRP, optional)">
                        <b-form-input placeholder="Sale Price" v-model="newProductData.sale_price"></b-form-input>
                    </b-form-group>

                    <b-form-group description="Clickthrough URL">
                        <b-form-input placeholder="Clickthrough URL" v-model="newProductData.link"></b-form-input>
                    </b-form-group>
                </b-form>

                <div class="text-center mt-2">
                    <b-button variant="outline-danger" size="sm" @click="cancelEdit" class="mr-1">Cancel</b-button>
                    <b-button variant="outline-dark" size="sm" @click="finishEdit" class="ml-1">Update</b-button>
                </div>
            </div>
        </b-card-body>
    </b-card> -->
</template>

<script>
import { isValidProduct } from "@/helpers";

export default {
    name: 'ProductComponent',
    props: {
        product: {
            type: Object,
            required: true
        },
        selected: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
            isEditing: false,
            newProductData: null,

            // Bootstrap
            availabilityOptions: [
                { text: 'None', value: '' },
                { text: 'In stock.', value: 'in stock' },
                { text: 'Out of stock.', value: 'out of stock' }
            ]
        }
    },

    computed: {
        editedIsValid() {
            return isValidProduct(this.newProductData);
        }
    },

    created() {
        this.newProductData = JSON.parse(JSON.stringify(this.$props.product));
    },

    methods: {
        addProduct() {
            this.$emit("addProduct", this.$props.product.id);
        },

        startEdit() {
            if (this.$props.selected && !this.isEditing) {
                console.log('EDIT PRODUCT');
                this.newProductData = JSON.parse(JSON.stringify(this.$props.product));
                this.isEditing = true;
            }
        },

        cancelEdit() {
            this.isEditing = false; 
        },

        finishEdit() {
            console.log("FINISH EDIT");
            this.$emit("editProduct", this.$props.product.id, this.newProductData);
            this.isEditing = false;
        },

        removeProduct() {
            this.$emit("removeProduct", this.$props.product.id);
        }
    }
}
</script>