<template>
    <b-card
        :img-src="productImageUrl"
        img-top
        :title="productTitle"
    >
        <b-card-text>
            <div v-if="productPrice">
                <div>
                    <span><strong>RRP</strong>: </span>
                    <!-- <span v-if="onSale">${{ product.Offers.Listings[0].Price.Amount + product.Offers.Listings[0].Price.Savings.Amount }}</span>
                    <span v-else>${{ product.Offers.Listings[0].Price.Amount }}</span> -->
                    <span>${{ productPrice }}</span>
                </div>

                <div v-if="productSalePrice">
                    <strong>Sale Price</strong>: ${{ productSalePrice }}
                </div>

                <div>
                    <strong>Availability</strong>: 
                    <span v-if="productAvailability">{{ productAvailability }}</span>
                    <span v-else>Not available</span>
                </div>
            </div>
            <div v-else>
                <em>Not currently sold by Amazon hence no prices returned.</em>
            </div>
        </b-card-text>

        <div class="text-center">
            <b-button variant="outline-success" size="sm" @click="addAmazonProduct" v-if="!selected">Add Product</b-button>
            <b-button variant="outline-dark" size="sm" @click="startEdit" v-if="selected" class="mr-1">Edit</b-button>
            <b-button variant="outline-success" size="sm" :href="productUrl" target="_blank" v-if="selected" class="ml-1 mr-1">URL</b-button>
            <b-button variant="outline-danger" size="sm" @click="removeAmazonProduct" v-if="selected" class="ml-1">Remove</b-button>
        </div>

        <b-modal centered ok-variant="success" button-size="sm" v-if="isEditing" v-model="isEditing" @cancel="cancelEdit" @hide="cancelEdit" @hidden="cancelEdit">
            <template #modal-header>
                <div>
                    <div><h5>Edit Product: {{ product.ASIN }}</h5></div>
                    <div class="text-muted font-small">Manual Updates will overwrite data from the Amazon API, thus making that data no longer live.</div>
                </div>
            </template>
            <b-img :src="productImageUrl" class="w-100" />
            <div class="mt-2">
                <div>
                    <div><h5>Image</h5></div>
                    <div><strong>Amazon:</strong></div>
                    <div><em>{{ product.Images.Primary.Large.URL }}</em></div>
                    <div class="mt-2"><strong>Manual:</strong></div>
                    <div><b-form-input placeholder="Image Link" v-model="manualUpdates.imageUrl" size="sm" /></div>
                </div>

                <div class="mt-3">
                    <div><h5>Title</h5></div>
                    <div><strong>Amazon:</strong></div>
                    <div><em>{{ product.ItemInfo.Title.DisplayValue }}</em></div>
                    <div class="mt-2"><strong>Manual:</strong></div>
                    <div><b-form-textarea rows="3" no-resize placeholder="Product Title" v-model="manualUpdates.title" size="sm" /></div>
                </div>

                <div class="mt-3">
                    <h5>Price</h5>
                    <div><strong>Amazon Price:</strong></div>
                    <div>
                        <span v-if="!hasAmazonPrice"><em>No price returned.</em></span>
                        <span v-else-if="!hasAmazonSalePrice"><em>{{ product.Offers.Listings[0].Price.Amount }}</em></span>
                        <span v-else><em>{{ product.Offers.Listings[0].Price.Savings.Amount + product.Offers.Listings[0].Price.Amount }}</em></span>
                    </div>
                    <div class="mt-2"><strong>Manual Price:</strong></div>
                    <div><b-form-input placeholder="Price" v-model="manualUpdates.price" size="sm" /></div>
                    <div class="mt-2"><strong>Amazon Sale Price:</strong></div>
                    <div>
                        <span v-if="!hasAmazonSalePrice"><em>No Sale Price returned.</em></span>
                        <span v-else><em>{{ product.Offers.Listings[0].Price.Amount }}</em></span>
                    </div>
                    <div class="mt-2"><strong>Manual Savings:</strong></div>
                    <div><b-form-input placeholder="Savings" v-model="manualUpdates.salePrice" size="sm" /></div>
                </div>

                <div class="mt-3">
                    <h5>Availability</h5>
                    <div><strong>Amazon Availability:</strong></div>
                    <div>
                        <span v-if="hasAmazonAvailability"><em>{{ this.$props.product.Offers.Listings[0].Availability.Message }}</em></span>
                        <span v-else><em>Not available</em></span>
                    </div>
                    <div class="mt-2"><strong>Manual Availability:</strong></div>
                    <div class="text-center mt-2">
                        <b-form-radio-group button-variant="outline-primary" v-model="manualUpdates.availability" :options="availabilityOptions" buttons size="sm" />
                    </div>
                </div>

                <div class="mt-3">
                    <h5>URL</h5>
                    <div><strong>Amazon URL:</strong></div>
                    <div><em>{{ product.DetailPageURL }}</em></div>
                    <div class="mt-2"><strong>Manual URL:</strong></div>
                    <div><b-form-input placeholder="URL" v-model="manualUpdates.url" size="sm" /></div>
                </div>
            </div>
            <template #modal-footer>
                <div class="text-center mt-2">
                    <b-button variant="outline-danger" size="sm" @click="cancelEdit" class="mr-1">Cancel</b-button>
                    <b-button variant="outline-dark" size="sm" @click="finishEdit" class="ml-1">Update</b-button>
                </div>
            </template>
        </b-modal>
    </b-card>
</template>

<script>
export default {
    name: 'AmazonProductComponent',
    props: {
        product: {
            type: Object,
            required: true
        },
        selected: {
            type: Boolean,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            isEditing: false,
            manualUpdates: null,

            // Bootstrap
            availabilityOptions: [
                { text: 'None', value: '' },
                { text: 'In stock.', value: 'In stock.' },
                { text: 'Out of stock.', value: 'Out of stock.' }
            ]
        }
    },

    created: function() {
        this.manualUpdates = this.$props.product.manualUpdates;
    },

    computed: {
        hasAmazonPrice: function() {
            if (this.$props.product.Offers && this.$props.product.Offers.Listings && this.$props.product.Offers.Listings[0] && this.$props.product.Offers.Listings[0].Price && this.$props.product.Offers.Listings[0].Price.Amount) {
                return true;
            }

            return false;
        },

        hasAmazonSalePrice: function() {
            if (this.hasAmazonPrice && this.$props.product.Offers.Listings[0].Price.Savings) {
                return true;
            }

            return false;
        },

        hasAmazonAvailability: function() {
            if (this.$props.product.Offers && this.$props.product.Offers.Listings && this.$props.product.Offers.Listings[0] && this.$props.product.Offers.Listings[0].Availability) {
                return true;
            }

            return false;
        },

        productImageUrl: function() {
            if (this.manualUpdates && this.manualUpdates.imageUrl) {
                return this.manualUpdates.imageUrl;
            }

            return this.$props.product.Images.Primary.Large.URL;
        },

        productTitle: function() {
            if (this.manualUpdates && this.manualUpdates.title) {
                return this.manualUpdates.title;
            }

            return this.$props.product.ItemInfo.Title.DisplayValue;
        },

        productPrice: function() {
            if (this.manualUpdates && this.manualUpdates.price) {
                return this.manualUpdates.price
            }

            if (this.hasAmazonPrice && this.hasAmazonSalePrice) {
                return this.$props.product.Offers.Listings[0].Price.Amount + this.$props.product.Offers.Listings[0].Price.Savings.Amount
            }

            if (this.hasAmazonPrice) {
                return this.$props.product.Offers.Listings[0].Price.Amount;
            }

            return false;
        },

        productSalePrice: function() {
            if (this.manualUpdates && this.manualUpdates.salePrice) {
                return this.manualUpdates.salePrice
            }

            // Return price as Amazon does Price + Savings Amount
            if (this.$props.product.Offers && this.$props.product.Offers.Listings && this.$props.product.Offers.Listings[0] && this.$props.product.Offers.Listings[0].Price.Savings) {
                return this.$props.product.Offers.Listings[0].Price.Amount;
            }

            return false;
        },

        productAvailability: function() {
            if (this.manualUpdates && this.manualUpdates.availability) {
                return this.manualUpdates.availability;
            }

            if (this.hasAmazonAvailability) {
                return this.$props.product.Offers.Listings[0].Availability.Message
            }

            return false;
        },

        productUrl: function() {
            if (this.manualUpdates && this.manualUpdates.url) {
                return this.manualUpdates.url;
            }

            if (this.$props.product.DetailPageURL) {
                return this.$props.product.DetailPageURL;
            }

            return false;
        },

        asin: function() {
            return this.$props.product.ASIN;
        }
    },

    methods: {
        addAmazonProduct: function() {
            this.$emit("addAmazonProduct", this.$props.index);
        },

        removeAmazonProduct: function() {
            this.$emit("removeAmazonProduct", this.$props.index);
        },

        startEdit: function() {
            if (this.$props.selected && !this.isEditing) {
                this.isEditing = true;
                this.manualUpdates = JSON.parse(JSON.stringify(this.$props.product.manualUpdates));
            }
        },

        cancelEdit: function(e) {
            console.log("CANCELLING EDIT");
            e.preventDefault();
            this.manualUpdates = this.$props.product.manualUpdates;
            this.isEditing = false;
        },

        finishEdit: function() {
            this.$emit("editAmazonProduct", this.manualUpdates, this.$props.index);
            this.isEditing = false;
        }
    },

    watch: {
        asin: function() {
            this.manualUpdates = this.$props.product.manualUpdates;
        }
    }
}
</script>

<style scoped>
    .font-small {
        font-size: 12px;
    }
</style>