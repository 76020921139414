<template>
    <b-row>
        <b-col lg="1" cols="0">

        </b-col>

        <b-col lg="10" cols="12">
            <b-container>
                <b-card no-body>
                    <b-card-body>
                        <b-card-title>Catalogue Connect Feed Builder</b-card-title>
                        <div>
                            <b-card-text>
                                <p>The Catalogue Connect Feed Builder is a tool that allows advertisers to condense their entire product feed (of potentially thousands of products) to a more manageable feed that can be used with the Catalogue Connect product.</p>
                                <p>For more information on Catalogue Connect, please see the <a href="https://www.newscorpaustralia.com/adspec/catalogue/" target="_blank">product page</a>, or alternatively please reach out to your account manager.</p>
                                <p><img src="https://www.newscorpaustralia.com/wp-content/uploads/2020/11/Catalogue_980x366_Nov-26-2020-13-50-22.gif" style="width:100%;height:auto;" /></p><br>
                                <div class="text-center">
                                    <b-button variant="success" @click="modalActive = !modalActive" class="mr-1">Begin</b-button>
                                    <!-- <b-button variant="outline-dark" @click="amazonNextStepOne" class="ml-1">Amazon</b-button> -->
                                </div>
                            </b-card-text>
                        </div>
                    </b-card-body>
                </b-card>

                <FeedLayoutComponent class="mt-5 mb-5" />

                <b-card no-body class="mt-5">
                    <b-card-body>
                        <b-card-title><h5>Examples</h5></b-card-title>

                        <div>
                            <b-card-text>
                                Please see live example <a href="https://www.news.com.au/?ad_test_env=cc_feed_test" target="_blank">here</a> and <a href="https://www.news.com.au/?ad_test_env=digitalcatalogue" target="_blank">here</a>.
                            </b-card-text>
                        </div>
                    </b-card-body>
                </b-card>
            </b-container>
        </b-col>

        <b-col lg="1" cols="0">

        </b-col>
        <b-modal v-model="modalActive" centered hide-footer>
            <template #modal-title>
                How would you like to activate your catalogue?
            </template>
            <div>
                <div class="mb-1"><strong>Create</strong></div>
                <b-list-group>
                    <b-list-group-item to="/feed/standard?ref=newmanualfeed">I'd like to build my own feed (manual)</b-list-group-item>
                    <b-list-group-item to="/feed/standard?ref=newgmcfeed">I'd like to supply my Google Merchant Center feed</b-list-group-item>
                    <b-list-group-item to="/feed/amazon?ref=newamazonfeed">I'd like to use the products from my Amazon shop front</b-list-group-item>
                </b-list-group>

                <div class="mt-2 mb-1"><strong>Edit</strong></div>
                <b-list-group>
                    <b-list-group-item to="/feed/standard">I'd like to edit an existing feed</b-list-group-item>
                    <b-list-group-item to="/feed/standardAPI">I'd like to edit an existing API feed</b-list-group-item>
                    <b-list-group-item to="/feed/amazon">I'd like to edit an existing Amazon feed</b-list-group-item>
                </b-list-group>
            </div>
            <template #modal-footer>
                <div></div>
            </template>
        </b-modal>
    </b-row>
</template>

<script>
import FeedLayoutComponent from '@/components/GoogleMerchantCenter/FeedLayoutComponent.vue'

export default {
    name: 'Home',
    components: { FeedLayoutComponent },
    data() {
        return {
            modalActive: false
        }
    },
    methods: {
        standardNextStepOne() {
            this.$router.push("/feed/standard");
        },

        amazonNextStepOne() {
            this.$router.push("/feed/amazon");
        }
    }
}
</script>