import Vue from 'vue'
import Vuex from 'vuex'
import { isValidProduct } from "@/helpers";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userProfile: null,
        feedType: '',
        step: 0,
        type: '',
        url: '',
        // Amazon
        amazonFeed: null,
        amazonChangelog: [],
        loadedAmazonProducts: [],

        // Standard
        standardFeed: null,
        standardChangelog: [],
        loadedStandardProducts: []
    },
    mutations: {
        setLoggedInUser(state, user) {
            state.userProfile = user;
        },

        setFeedTypeAndStep(state, arr) {
            state.feedType = arr[0];
            state.step = arr[1]
        },

        // AMAZON MUTATIONS
        setAmazonFeed(state, feed) {
            state.amazonFeed = feed;
        },

        setLoadedAmazonProducts(state, feed) {
            state.loadedAmazonProducts = feed;
        },

        addAmazonProduct(state, product) {
            state.amazonFeed.products.push(product);

            state.amazonChangelog.push({
                type: 'add',
                asin: product.ASIN
            })
        },

        editAmazonProduct(state, data) {
            Vue.set(state.amazonFeed.products[data.index], 'manualUpdates', data.updates);

            state.amazonChangelog.push({
                type: 'update',
                asin: state.amazonFeed.products[data.index].ASIN,
                manualUpdates: data.updates
            })
        },

        removeAmazonProduct(state, index) {
            state.amazonChangelog.push({
                type: 'delete',
                asin: state.amazonFeed.products[index].ASIN
            })

            state.amazonFeed.products.splice(index, 1);
        },

        addAmazonProductToLoaded(state, product) {
            state.loadedAmazonProducts.push(product);
        },

        removeAmazonProductFromLoaded(state, index) {
            state.loadedAmazonProducts.splice(index, 1);
        },

        resetAmazonChangelog(state) {
            state.amazonChangelog = [];
        },

        // Standard MUTATIONS
        setStandardFeed(state, feed) {
            state.standardFeed = feed;
        },

        setStandardLoadedProducts(state, feed) {
            state.loadedStandardProducts = feed;
        },

        addStandardFeedToLoaded(state, feed) {
            // TODO: Add logic to confirm no duplicate IDs are being added.
            state.loadedStandardProducts = feed;
        },

        addStandardProduct(state, product) {
            if (!state.standardFeed && state.standardFeed.products) {
                throw new Error("No standard feed");
            }

            if (!isValidProduct(product)) {
                throw new Error("Invalid product");
            }

            state.standardFeed.products.push(product);
        },

        editStandardProduct(state, data) {
            console.log("EDITING STANDARD PRODUCT");
            Vue.set(state.standardFeed.products, data.index, data.newProductData);
        },

        removeStandardProduct(state, index) {
            state.standardFeed.products.splice(index, 1);
        },

        addStandardProductToLoaded(state, product) {
            // TODO: Add logic to confirm no duplicate IDs are being added.
            state.loadedStandardProducts.push(product);
        },

        removeStandardProductFromLoaded(state, index) {
            state.loadedStandardProducts.splice(index, 1);
        },

        addMultipleStandardProducts(state, indices) {
            // To avoid calling a Vue update on every array push, instead build a new array and concatenate the 2.
            let productsToAdd = indices.map(x => state.loadedStandardProducts[x]);
            Vue.set(state.standardFeed, "products", state.standardFeed.products.concat(productsToAdd));

            state.loadedStandardProducts = state.loadedStandardProducts.filter((x, i) => !(indices.includes(i)));
        },

        resetStandardChangelog(state) {
            state.standardChangelog = [];
        },

        setFeedType(state, feed){
            state.standardFeed.type = feed
        },

        setURL(state, url){
            state.standardFeed.url = url
        }
    },
    actions: {
        fetchUser({ commit }, user) {
            if (user) {
                commit("setLoggedInUser", { loggedIn: true, data: user });
            } else {
                commit("setLoggedInUser", { loggedIn: false, data: null });
            }
        }
    }
});