<template>
    <b-form @submit.prevent="buildProducts" class="d-flex flex-column">
        <label for="fileInput">Upload Google Merchant Center feed:</label>
        <div id="fileInputCont">
            <b-form-file id="fileInput" class="fileInput" v-model="fileInput" accept="application/zip, text/plain, text/csv, text/xml"></b-form-file>
        </div>

        <div class="buttons mt-2 mx-auto">
            <b-button variant="outline-dark" size="sm" @click="clearInputs" :disabled="isBuilding">Clear</b-button>
            <b-button variant="outline-success" size="sm" class="ml-2" type="submit" :disabled="isBuilding || !fileInput">
                <span v-if="!isBuilding">Add Products</span>
                <span v-else><b-spinner small /></span>
            </b-button>
        </div>
    </b-form>
</template>

<script>
import parser from 'xml2js'
import Papa from 'papaparse'

export default {
    name: 'GMCInputForm',
    // props: {
    //     isNew: {
    //         type: Boolean,
    //         required: true
    //     }
    // },
    data() {
        return {
            fileInput: null,
            isBuilding: false,
            // Will only read the below tags from the input!
            acceptedTags: ["id", "title", "link", "price", "image_link", "sale_price", "brand", "google_product_category", "product_type", "availability", "condition"]
        }
    },

    methods: {
        buildProducts: function() {
            this.isBuilding = true;

            if (this.fileInput) {
                if (this.fileInput.type == "application/zip" || this.fileInput.type === "text/plain" || this.fileInput.type === "text/csv" || this.fileInput.type === "text/xml") {
                    // this.$emit("addFeed", this.fileInput);
                    this.addFeed(this.fileInput)
                } else {
                    console.log(this.fileInput);
                    alert("Error, file type not recognised! " + this.fileInput.type + ". Please ensure you are using either a TSV or CSV document.");
                    this.fileInput = null;
                    this.isBuilding = false;
                }
            }
        },

        clearInputs: function() {
            this.fileInput = null;
        },

        async addFeed(file) {
            try {
                if (file.type === "application/zip") {
                    const reader = new window.zip.ZipReader(new window.zip.BlobReader(file));
    
                    // First unzip the file.
                    const entries = await reader.getEntries();

                    if (!entries.length) {
                        console.error("No entries in zip.");
                        throw new Error("No entries in unzip");
                    }

                    const data = await entries[0].getData(new window.zip.TextWriter());

                    // Then convert the unzipped file to an Array of objects.
                    const feed = this.convertCsv(data);

                    this.$store.commit("addStandardFeedToLoaded", feed);
                    this.$emit("feedAdded");
                } else if (file.type === "text/plain" || file.type === "text/csv") {
                    // CSV:
                    const data = await this.readFileAsText(file);
                    const feed = this.convertCsv(data);
                    this.$store.commit("addStandardFeedToLoaded", feed);
                    this.$emit("feedAdded");
                } else if (file.type == "text/xml") {
                    // TODO: Should we support XML?
                    // XML:
                    const data = await this.readFileAsText(file);
                    const result = await parser.parseStringPromise(data, { 
                        explicitArray: false, 
                        ignoreAttrs: true, 
                        tagNameProcessors: [(name) => {
                            // Remove g: from XML Tag.
                            if (name.substring(0, 2) == "g:") {
                                return name.substring(2, name.length);
                            } else {
                                return name;
                            }
                        }],
                        valueProcessors: [(value, name) => {
                            // If any value surrounded by quotation marks, remove them.
                            if (value && value.charAt(0) == "\"" && value.charAt(value.length - 1) == "\"") {
                                value = value.substring(1, value.length - 1);
                            }
    
                            // Remove AUD from price and sale_price
                            if (value && (name == "price" || name == "sale_price")) {
                                if (value.substring(value.length - 4, value.length) == " AUD") {
                                    value = value.substring(0, value.length - 4);
                                } else if (value.substring(value.length - 3, value.length) == "AUD") {
                                    value = value.substring(0, value.length - 3);
                                }
                            }
    
                            // Check if image link is HTTP and not HTTPS, if so make HTTPS
                            if (value && name == "image_link") {
                                if (value.substring(0, 5) == "http:") {
                                    value = "https" + value.substring(4, value.length);
                                }
                            }
                            
                            return value;
                        }]
                    });
    
                    let feed = result.rss.channel.item;
    
                    // Ensure we are only passing through the required keys/values.
                    for (let i = 0; i < feed.length; i ++) {
                        const temp = feed[i];
    
                        feed[i] = {
                            id: temp.id,
                            title: temp.title,
                            link: temp.link,
                            price: temp.price,
                            image_link: temp.image_link
                        }
    
                        if (temp.sale_price) {
                            feed[i].sale_price = temp.sale_price;
                        }
    
                        if (temp.brand) {
                            feed[i].brand = temp.brand;
                        }
    
                        if (temp.google_product_category) {
                            feed[i].google_product_category = temp.google_product_category;
                        }   
                        
                        if (temp.product_type) {
                            feed[i].product_type = temp.product_type;
                        }
    
                        if (temp.availability) {
                            feed[i].availability = temp.availability;
                        }
    
                        if (temp.condition) {
                            feed[i].condition = temp.condition;
                        }
                    }

                    this.$store.commit("addStandardFeedToLoaded", feed);
                    this.$emit("feedAdded");
                } else {
                    console.log(file);
                    alert("Error, file type not recognised!" + file.type);
                    throw new Error("File type unrecognised!");
                }
            }
            catch (err) {
                // TODO: Add better error handling.
                console.error("Error unzipping file.", err);
            }
        },

        convertCsv(input) {
            let temp = [];

            Papa.parse(input, {
                complete: results => {
                    // Convert array of arrays to array of objects.
                    if (results.data.length > 2) {
                        let headers = results.data[0];
    
                        for (let i = 1; i < results.data.length; i ++) {
                            let obj = {};
                            let currentLine = results.data[i];

                            for (let j = 0; j < currentLine.length; j ++) {
                                if (this.acceptedTags.includes(headers[j])) {
                                    let val = currentLine[j];
    
                                    if (headers[j] === "price" || headers[j] === "sale_price") {
                                        val = Number(val);
                                    }
    
                                    obj[headers[j]] = val;
                                }
                            }

                            temp.push(obj)
                        }
                    } else {
                        throw new Error("Not enough results!");
                    }
                },
                error: function(err) {
                    throw err;
                }
            })

            return temp;
        },

        readFileAsText(file) {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                reader.readAsText(file);
            })
        },
    }
}
</script>

<style>
#fileInputCont .custom-file-label:hover {
    cursor: pointer;
}
</style>
