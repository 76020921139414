<template>
    <b-row>
        <b-col lg="3" cols="0">

        </b-col>
        <b-col lg="6" cols="12">
            <b-container>
                <b-card no-body>
                    <b-card-body>
                        <b-card-title>Verify Email</b-card-title>
                        <div>
                            <p>Your email is unverified, please press the button below to get sent a verification email.</p>
                            <p>Once received, please click the link in the email to verify your email.</p>
                            <p>Once verified, please refresh this page.</p>
                            <p>Otherwise, click <b-link href="#" @click="signOut">here</b-link> to log out.</p>
                            <div class="text-center">
                                <b-button variant="outline-dark" @click="verifyEmail" :disabled="verifyCountdown > 0 || isLoading" size="sm">
                                    <div v-if="!isLoading">Send Email</div>
                                    <div v-else><b-spinner small /></div>
                                </b-button>
                                <div v-if="verifyCountdown > 0" class="font-small text-muted mt-1">Email verification sent. Please wait {{ verifyCountdown }} seconds before trying again.</div>
                            </div>
                        </div>
                    </b-card-body>
                </b-card>
            </b-container>
        </b-col>
        <b-col lg="3" cols="0">

        </b-col>
    </b-row>
</template>

<script>
import { auth } from "@/firebase"

export default {
    name: 'VerifyEmail',
    data() {
        return {
            isLoading: false,
            verifyCountdown: 0,
            verifyTimer: 60,
            verifyInterval: null,
        }
    },
    methods: {
        verifyEmail: async function() {
            this.isLoading = true;
            await auth.currentUser.sendEmailVerification();
            this.verifyCountdown = this.verifyTimer;
            this.isLoading = false;

            this.verifyInterval = window.setInterval(() => {
                this.verifyCountdown --;
                if (this.verifyCountdown <= 0) {
                    window.clearInterval(this.verifyInterval)
                }
            }, 1000)
        },

        signOut: function() {
            auth.signOut();
        }
    }
}
</script>

<style scoped>
    .font-small {
        font-size: 12px;
    }
</style>