<template>
    <b-card no-body>
        <b-card-body>
            <b-card-title><h5>Input Feed Layout</h5></b-card-title>
            <div>
                <b-card-text>
                    <p>The Catalogue Connect input feed must follow the Google Merchant Center Product Feed layout.</p>
                    <p>File must either be a TSV or a ZIP of a TSV file, and layout of each product within this feed can be found <a href="https://support.google.com/merchants/answer/7052112" target="_blank">here</a>.</p>
                    <p>Required fields:
                        <ul>
                            <li>id</li>
                            <li>title</li>
                            <li>link</li>
                            <li>price</li>
                            <li>image_link</li>
                        </ul>
                    </p>
                    <p>Optional fields:
                        <ul>
                            <li>sale_price</li>
                            <li>brand</li>
                            <li>google_product_category</li>
                            <li>product_type</li>
                            <li>availability</li>
                            <li>condition</li>
                        </ul>
                    </p>
                </b-card-text>
            </div>
        </b-card-body>
    </b-card>
</template>

<script>
export default {
    name: 'FeedLayoutComponent'
}
</script>