<template>
    <b-form @submit.prevent="add">
        <b-form-group label="Image URL">
            <b-input size="sm" v-model="product.image_link" />
        </b-form-group>
        <b-form-group label="Name">
            <b-input size="sm" v-model="product.title" />
        </b-form-group>
        <b-form-group label="Price">
            <b-input size="sm" v-model="product.price" type="number" />
        </b-form-group>
        <b-form-group label="Sale price (optional)">
            <b-input size="sm" v-model="product.sale_price" />
        </b-form-group>
        <b-form-group label="Clickthrough">
            <b-input size="sm" v-model="product.link" />
        </b-form-group>
        <b-form-group label="Google Product Category (optional)">
            <b-input size="sm" v-model="product.google_product_category" />
        </b-form-group>
        <b-form-group label="Brand (optional)">
            <b-input size="sm" v-model="product.brand" />
        </b-form-group>
        <b-form-group label="Product type (optional)">
            <b-input size="sm" v-model="product.product_type" />
        </b-form-group>
        <b-form-group label="Condition (optional)">
            <b-input size="sm" v-model="product.condition" />
        </b-form-group>
        <b-form-group label="Availability (optional)">
            <div class="text-center">
                <b-form-radio-group button-variant="outline-primary" v-model="product.availability" :options="availabilityOptions" buttons size="sm" />
            </div>
        </b-form-group>

        <div class="text-center mb-2">
            <b-button variant="success" :disabled="!productValid" type="submit">Add Product</b-button>
        </div>

        <div class="text-muted font-small">Note: Optional values are used for filtering and will not be shown in the unit, except Sale Price.</div>
    </b-form>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { isValidProduct } from "@/helpers";

export default {
    name: "ProductAddForm",
    data() {
        return {
            product: {
                id: "",
                title: "",
                image_link: "",
                link: "",
                price: 0,
                availability: "",
                google_product_category: "",
                brand: "",
                product_type: "",
                sale_price: 0,
                condition: ""
            },

            availabilityOptions: [
                { text: "None", value: '' },
                { text: "In stock.", value: 'in stock' },
                { text: "Out of stock.", value: 'out of stock' }
            ]
        }
    },

    computed: {
        productValid() {
            return isValidProduct(this.product);
        }
    },

    created() {
        this.product.id = uuidv4();
    },

    methods: {
        add() {
            if (!isValidProduct(this.product)) {
                console.error("Error. Product isn't valid.")
                return;
            }

            let p = JSON.parse(JSON.stringify(this.product));

            if (!p.google_product_category) {
                delete p.google_product_category;
            }

            if (!p.brand) {
                delete p.brand;
            }

            if (!p.product_type) {
                delete p.product_type;
            }

            if (!p.sale_price) {
                delete p.sale_price;
            }

            if (!p.condition) {
                delete p.condition;
            }

            this.$store.commit("addStandardProductToLoaded", p);
            this.$emit("productAdded", p);
        }
    }
}
</script>

<style scoped>
    .font-small {
        font-size: 12px !important;
    }
</style>