<template>
    <b-row>
        <b-col lg="3" cols="0">

        </b-col>
        <b-col lg="6" cols="12">
            <b-container>
                <!-- LOGIN -->
                <b-card no-body v-if="isLogin">
                    <b-card-body>
                        <b-card-title>Login</b-card-title>
                        <b-card-sub-title>Login with your credentials below, or <b-link href="#" @click="toggleSignUp">Sign Up</b-link> instead.</b-card-sub-title>
                        
                        <div class="mt-3" v-if="!isForgotPassword">
                            <b-form @submit.prevent="login">
                                <b-form-group label="Email:" :disabled="isLoading">
                                    <b-input type="email" v-model="loginForm.email" placeholder="Input email" />
                                </b-form-group>
                                <b-form-group label="Password:" :disabled="isLoading">
                                    <b-input type="password" v-model="loginForm.password" placeholder="Input password" />
                                </b-form-group>

                                <div class="d-flex">
                                    <div class="ml-auto">
                                        <b-button size="sm" @click="isForgotPassword = true;" variant="outline-dark" class="mr-1" :disabled="isLoading">
                                            Forgot Password
                                        </b-button>
                                        <b-button type="submit" size="sm" variant="outline-success" class="ml-1" :disabled="!canLogin">
                                            <div v-if="!isLoading">Login</div>
                                            <div v-else><b-spinner small /></div>
                                        </b-button>
                                    </div>
                                </div>
                            </b-form>
                        </div>
                        <div class="mt-3" v-else>
                            <b-form @submit.prevent="forgotPassword">
                                <b-form-group label="Email:" :disabled="isLoading">
                                    <b-input type="email" v-model="loginForm.email" placeholder="Input email" />
                                </b-form-group>

                                <div class="d-flex">
                                    <div class="ml-auto">
                                        <b-button size="sm" @click="isForgotPassword = false;" variant="outline-dark" :disabled="isLoading">
                                            Go Back
                                        </b-button>
                                        <b-button type="submit" size="sm" variant="outline-success" class="ml-1" :disabled="!canResetPassword">
                                            <div v-if="!isLoading">Send Reset</div>
                                            <div v-else><b-spinner small /></div>
                                        </b-button>
                                    </div>
                                </div>
                            </b-form>
                        </div>

                        <div class="mt-1">
                            <b-alert variant="danger" fade :show="alertCountdown" dismissible @dismissed="alertCountdown = 0" @dismiss-count-down="alertCountdownChanged">
                                {{ errorMessage }}
                            </b-alert>

                            <b-alert variant="success" fade :show="successAlertCountdown" dismissible @dismissed="successAlertCountdown = 0" @dismiss-count-down="successAlertCountdownChanged">
                                {{ successMessage }}
                            </b-alert>
                        </div>
                    </b-card-body>
                </b-card>

                <!-- SIGNUP -->
                <b-card no-body v-else>
                    <b-card-body>
                        <b-card-title>Sign Up</b-card-title>
                        <b-card-sub-title>Sign Up with your work email below, or <b-link href="#" @click="toggleSignUp">Log In</b-link> instead.</b-card-sub-title>

                        <div class="mt-3">
                            <b-form @submit.prevent="signUp">
                                <b-form-group label="Email" :disabled="isLoading">
                                    <b-input type="email" v-model="signUpForm.email" placeholder="Input email" />
                                </b-form-group>
                                <b-form-group label="Password" description="Passwords must be at least 6 characters." :disabled="isLoading">
                                    <b-input type="password" v-model="signUpForm.password" placeholder="Input password" />
                                </b-form-group>
                                <b-form-group label="Confirm password" :disabled="isLoading">
                                    <b-input type="password" v-model="signUpForm.confPassword" placeholder="Confirm Password" />
                                </b-form-group>

                                <div class="d-flex">
                                    <div class="ml-auto">
                                        <b-button type="submit" size="sm" variant="outline-success" class="ml-1" :disabled="!canSignUp">
                                            <div v-if="!isLoading">Sign Up</div>
                                            <div v-else><b-spinner small /></div>
                                        </b-button>
                                    </div>
                                </div>
                            </b-form>
                        </div>
                        <div class="mt-2">
                            <b-alert variant="danger" fade :show="alertCountdown" dismissible @dismissed="alertCountdown = 0" @dismiss-count-down="alertCountdownChanged">
                                {{ errorMessage }}
                            </b-alert>
                        </div>
                    </b-card-body>
                </b-card>
            </b-container>
        </b-col>
        <b-col lg="3" cols="0">

        </b-col>
    </b-row>
</template>

<script>
import { auth } from '@/firebase'

export default {
    name: 'Login',
    data() {
        return {
            // Set to true or false based on if user is logging in or signing up.
            isForgotPassword: false,
            isLogin: true,
            isLoading: false,
            loginForm: {
                email: '',
                password: ''
            },
            signUpForm: {
                email: '',
                password: '',
                confPassword: ''
            },

            // Error Handling
            dismissSecs: 10,
            alertCountdown: 0,
            errorMessage: '',
            successAlertCountdown: 0,
            successMessage: '',
        }
    },

    computed: {
        canLogin() {
            if (this.isLoading) {
                return false;
            }

            if (this.loginForm.email.length == 0) {
                return false;
            }

            if (this.loginForm.password.length < 6) {
                return false;
            }

            return true;
        },

        canSignUp() {
            if (this.isLoading) {
                return false;
            }

            if (this.signUpForm.email.length == 0) {
                return false;
            }

            if (this.signUpForm.password.length < 6) {
                return false;
            }

            if (this.signUpForm.password != this.signUpForm.confPassword) {
                return false;
            }

            return true;
        },

        canResetPassword() {
            if (this.isLoading) {
                return false;
            }

            if (!this.loginForm.email) {
                return false
            }

            return true;
        }
    },

    methods: {
        async login() {
            this.isLoading = true;
            await auth.signInWithEmailAndPassword(this.loginForm.email, this.loginForm.password)
            .catch(err => {
                console.error(err);
                this.errorMessage = err.message;
                this.alertCountdown = this.dismissSecs;
                this.isLoading = false;
            });
        },

        toggleSignUp() {
            if (!this.isLoading) {
                this.errorMessage = "";
                this.alertCountdown = 0;
                this.isLogin = !this.isLogin;
                this.isForgotPassword = false;
            }
        },

        async signUp() {
            this.isLoading = true;

            await auth.createUserWithEmailAndPassword(this.signUpForm.email, this.signUpForm.password)
            .catch(err => {
                console.error(err);
                this.errorMessage = err.message;
                this.alertCountdown = this.dismissSecs;
                this.isLoading = false;
            });
        },

        async forgotPassword() {
            this.isLoading = true;

            try {
                await auth.sendPasswordResetEmail(this.loginForm.email);
                this.successMessage = "Password reset email sent successfully. Please check your inbox.";
                this.successAlertCountdown = this.dismissSecs;
                this.isForgotPassword = false;
            } catch (err) {
                console.error(err);
                this.errorMessage = err.message;
                this.alertCountdown = this.dismissSecs;
            } finally {
                this.isLoading = false;
            }
        },

        alertCountdownChanged(alertCountdown) {
            this.alertCountdown = alertCountdown;
        },

        successAlertCountdownChanged(alertCoundown) {
            this.successAlertCountdown = alertCoundown;
        }
    }
}
</script>

<style scoped>
    .font-small {
        font-size: 12px;
    }
</style>