<template>
            <div class="mt-4">
                <div class="adUnit" :style="'background-image: url(' + computedBackgroundImage + ');'">
                    <a id="pButt" @click="carouselSlide(-1)">&#10094;</a>
                    <a id="nButt" @click="carouselSlide(1)">&#10095;</a>

                    <div id="carouselHeader">
                        <div class="carouselLogo">
                        </div>
                        <div class="carouselHero">
                        </div>
                    </div>

                    <div id="carouselTiles" scrollValue="0" @scroll="handleScroll">
                        <div class="productTile" v-for="(product, index) in loadedProducts" :key="index">
                            <div v-if="!product.sale_price || (product.sale_price >= product.price)">
                                <a :href="product.link" target="_blank">
                                    <div class='tileImage'>
                                        <img :src='product.image_link' />
                                    </div>
                                    <div class='tileContent'>
                                        <div class='productTitle'>
                                            <div>{{ product.title }}</div>
                                        </div>
                                        <div class='productInfoCont'>
                                            <div class='priceTag'>
                                                <div class='productPrice' style="margin-bottom:27px;"><sup class='priceSup'>$</sup>{{product.price.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}</div>
                                                <div class='callToAction'>Shop Now</div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div v-else>
                                <a :href="product.link" target='_blank'>
                                    <div class='tileImage'>
                                        <img :src="product.image_link" />
                                    </div>
                                    <div class='tileContent'>
                                        <div class='productTitle'>
                                            <div>{{ product.title }}</div>
                                        </div>
                                        <div class='productInfoCont'>
                                            <div class='priceTag'>
                                                <div class='prices'>
                                                    <div class='productPrice'><sup class='priceSup'>$</sup>${{ product.sale_price.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}</div> 
                                                    <div class='originalPrice'>${{product.price.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}</div>
                                                    <div class='discount'>You save ${{ (product.price - product.sale_price).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })  }}</div>
                                                </div>
                                                <div class='callToAction'>Shop Now</div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</template>

<script>
import defaultBackground from '@/assets/defaultBackground.png';

export default {
    name: 'CatalogueComponent',
    props: {
        feed: {
            type: Array,
            required: true
        },
        backgroundImage: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            logoImageFile: null,
            logoImageURL: '',
            heroImageFile: null,
            heroImageURL: '',

            backgroundColor: "#FFFFFF",

            unloadedProducts: [],
            loadedProducts: [],

            isScrolling: null,
            leftChevronHidden: true,
            rightChevronHidden: false,
        }
    },

    computed: {
        computedBackgroundImage() {
            if (!this.backgroundImage) {
                return defaultBackground
            }

            return this.backgroundImage;
        },
    },

    created() {
        this.unloadedProducts = JSON.parse(JSON.stringify(this.$props.feed));
        this.buildTiles(5);
    },

    methods: {
        buildTiles(x) {
            for (let i = 0; i < x; i ++) {
                if (this.unloadedProducts.length > 0) {
                    const randNum = Math.floor(Math.random() * this.unloadedProducts.length);
                    this.loadedProducts.push(this.unloadedProducts[randNum]);
                    this.unloadedProducts.splice(randNum, 1);
                }
            }
        },

        carouselSlide(x) {
            if (this.loadedProducts.length > 0) {
                const carouselTilesEl = document.querySelector("#carouselTiles");

                let scrollValue = Number(carouselTilesEl.getAttribute("scrollValue"));
                scrollValue += x;

                if (scrollValue < 0) {
                    scrollValue = 0;
                } else if (scrollValue > this.loadedProducts.length - 1) {
                    this.buildTiles(5);
                }

                carouselTilesEl.setAttribute("scrollValue", scrollValue);

                this.$nextTick(() => {
                    carouselTilesEl.scrollTo({
                        left: scrollValue * 278,
                        behavior: "smooth"
                    });
                })
            }
        },

        handleScroll() {
            window.clearTimeout( this.isScrolling );

            const carouselTilesEl = document.querySelector("#carouselTiles");

            this.isScrolling = setTimeout(() => {
                let scrollValue = Number(carouselTilesEl.getAttribute("scrollValue"));

                if (carouselTilesEl.scrollLeft != scrollValue * 280) {
                    scrollValue = Math.round(carouselTilesEl.scrollLeft / 280);
                    carouselTilesEl.setAttribute("scrollValue", scrollValue);
                    this.carouselSlide(0);
                }
            }, 150)

            // Add or hide left chevron icon.
            if (carouselTilesEl.scrollLeft == 0 && !this.leftChevronHidden) {
                document.querySelector("#pButt").style.display = "none";
                this.leftChevronHidden = true;
            } else if (this.leftChevronHidden && carouselTilesEl.scrollLeft != 0) {
                document.querySelector("#pButt").style.display = "block";
                this.leftChevronHidden = false;
            }
            // Add or hide right icon.
            else if (this.unloadedProducts.length == 0 && carouselTilesEl.scrollLeft >= (this.loadedProducts.length - 2) * 278 && !this.rightChevronHidden) {
                document.querySelector("#nButt").style.display = "none";
                this.rightChevronHidden = true;
            } else if (carouselTilesEl.scrollLeft < (this.loadedProducts.length - 2) * 278 && this.rightChevronHidden) {
                document.querySelector("#nButt").style.display = "block";
                this.rightChevronHidden = false;
            }
        }
    }
}
</script>

<style scoped>
    .adUnit {
        margin: 0 auto;
        border: gray 1px solid;
        width: 300px;
        height: 600px;
        padding: 10px;
        position: relative;
        box-sizing: border-box;
        background-repeat: no-repeat;
        background-position: center;
    }

    #pButt,
    #nButt {
        position: absolute;
        color: white;
        padding: 1px;
        font-size: 45px;
        background-color: rgba(0, 0, 0, .2);
        top: 375px;
        z-index: 2;
    }

    #nButt {
        right: -1px;
    }

    #pButt {
        left: -1px;
        display: none;
    }

    #pButt:hover,
    #nButt:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, .9);
    }

    #carouselHeader {
        display: block;
    }
    
    .carouselLogo {
        width: 280px;
        height: 30px;
        margin-top: 20px;
    }
    
    .carouselHero {
        width: 280px;
        height: 100px;
    }

    #carouselTiles {
        display: inline-block;
        margin-top: 10px;
        white-space: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        width: 100%;
        height: 400px;
    }

    .productTile {
        width: 268px;
        height: 390px;
        display: inline-block;
        vertical-align: middle;
        margin: 5px;
        box-sizing: border-box;
        border-radius: 10px;
        overflow-y: hidden;
        box-shadow: 0 0 5px grey;
        padding: 0;
        background: white;
        color: #000000;
    }

    .productTile a {
        text-decoration: none;
        color: inherit;
    }

    .tileImage {
        width: 200px;
        height: 200px;
        margin: 0 auto;
        margin-top: 2px;
        display: flex;
    }

    .tileImage img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        align-self: center;
        margin: 0 auto;
    }

    .tileContent {
        height: 182px;
        overflow: hidden;
        padding: 0 5px;
        margin-top: 5px;
    }

    .productInfoCont {
        display: block;;
        margin-top: 2px;
        height: 100px;
    }

    .productDescription,
    .priceTag {
        width: calc(50% - 5px);
        margin: 0;
        overflow: hidden;
        white-space: normal;
        font-family: 'Custom Font', Arial, sans-serif;
        position: relative;
        display: inline-block;
    }

    .productDescription {
        height: 100%;
        margin-right: 5px;
        font-size: 11px;
    }

    .priceTag {
        width: calc(100% - 10px);
        text-align: center;
        box-sizing: border-box;
        margin-left: 5px;
        color: black;
    }

    .priceSup {
        font-size: small;
    }

    .productPrice {
        font-size: 38px;
        line-height: 25px;
        font-weight: 800;
        margin-bottom: 5px;
    }

    .callToAction {
        font-size: 14px;
        line-height: 28px;
        text-transform: none;
        color: #0F1111;
        background: #FFA41C;
        border: 1px solid #FF8F00;
        border-radius: 20px;
        box-shadow: 0 2px 5px 0 rgb(213 217 217 / 50%);
    }

    .callToAction:hover {
        background: #FA8900;
        border-color: #E3931E;
        box-shadow: 0 2px 5px 0 rgb(213 217 217 / 50%);
    }

    .productTitle {
        overflow: hidden;
        white-space: normal;
        font-size:18px;
        font-family: "Custom Font", Arial, sans-serif;
        position: relative;
        width: 100%;
        height: 67px;
        text-align:center;
        vertical-align:top;
    }

    .priceTag .prices {
        align-items: center;
        justify-content: space-evenly;
        font-size: 15px;
        margin-bottom: 5px;
    }

    .prices .originalPrice {
        position: relative;
    }
    
    .originalPrice, .discount {
        display: inline;
    }
    
    .discount{
        color: #B12704;
    }

    .prices .originalPrice::after {
        content:'';
        position:absolute;
        top:50%;
        right:0;
        width:100%;
        height:3px;
        background:#B12704;
        -webkit-transform:translateY(-50%) rotate(-15deg);
        transform:translateY(-50%) rotate(-15deg);
        -webkit-clip-path:unset;
        clip-path:unset;
        clip:unset;
        overflow:hidden;
        white-space:nowrap;
    }

    .productDisclaimer {
        font-family: 'Custom Font', Arial, Helvetica, sans-serif;
        text-align: center;
        font-size: 10px;
        margin-left: 5px;
        margin-top: 3px;
    }

    .unitDisclaimer {
        font-family: 'Custom Font', Arial, Helvetica, sans-serif;
        font-size: 11px;
        padding: 0 5px 5px;
        color: white;
        text-align: left;
    }
    
    #impTracker {
        display: none;
    }

    .loader {
        border: 6px solid #f3f3f3;
        border-top: 6px solid #555;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        animation: spin 2s linear infinite;
        align-self: center;
        margin: 0 auto;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    /* Hide Scrollbar */
    #carouselTiles::-webkit-scrollbar {
        display: none;
    }

    #carouselTiles {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    /* Prevent Chevron Highlight */
    #pButt,
    #nButt {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    }
</style>