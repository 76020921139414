<template>
    <div class="adUnit" :style="computedBackground">
        <div id="catalogueContainer">
            <a id="pButt" class="prev" @click="carouselSlide(-1)">&#10094;</a>
            <a id="nButt" class="next" @click="carouselSlide(1)">&#10095;</a>
            <div id="carouselHeader">
                <div class="carouselLogo">
                </div>
                <div class="carouselHero">
                </div>
            </div>

            <div id="carouselTiles" @scroll="handleScroll()">
                <div v-for="(product, index) in filteredFeed" :key="index" class="productTile">
                    <div v-if="productValid(product)">
                        <a v-if="!productSalePrice(product)" :href='productURL(product)' target='_blank'>
                            <div class='tileImage'>
                                <img :src='productImage(product)' />
                            </div>
                            <div class='tileContent'>
                                <div class='productTitle'>
                                    <div>{{ productTitle(product) }}</div>
                                </div>
                                <div class='productInfoCont'>
                                    <div class='priceTag'>
                                        <div class='productPrice' style="margin-bottom:27px;"><sup class='priceSup'>$</sup>{{ productPrice(product).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}</div>
                                        <div class='callToAction'>Shop Now</div>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a v-else :href='productURL(product)' target='_blank'>
                            <div class='tileImage'>
                                <img :src='productImage(product)' />
                            </div>
                            <div class='tileContent'>
                                <div class='productTitle'>
                                    <div>{{ productTitle(product) }}</div>
                                </div>
                                <div class='productInfoCont'>
                                    <div class='priceTag'>
                                        <div class='prices'>
                                            <div class='productPrice'><sup class='priceSup'>$</sup>{{ productSalePrice(product).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}</div> 
                                            <div class='originalPrice'>${{ productPrice(product).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}</div>
                                            <div class='discount'>You save ${{ (productPrice(product) - productSalePrice(product)).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}</div>
                                        </div>
                                        <div class='callToAction'>Shop Now</div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import defaultAmazonBackground from '@/assets/defaultBackground.png'

export default {
    name: 'AmazonCatalogueComponent',
    props: {
        feed: {
            type: Object,
            required: true
        },
        background: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            filteredFeed: [],
            isScrolling: null,
            carouselTilesEl: null,
            scrollValue: 0,
            leftChevronHidden: true,
            rightChevronHidden: false,
        }
    },

    created: function() {
        this.filteredFeed = this.$props.feed.products.filter(x => { return this.productValid(x) })
    },

    mounted: function() {
        this.carouselTilesEl = document.querySelector("#carouselTiles");
        this.carouselTilesEl.setAttribute("scrollValue", 0);
    },

    computed: {
        computedBackground: function() {
            if (!this.$props.background) {
                return "background-image: url(" + defaultAmazonBackground + ");"
            }

            return "background-image: url(" + this.$props.background + ");"
        },
    },

    methods: {
        productValid: function(product) {
            // First remove if they have forced it to be out of stock.
            if (product.manualUpdates.availability.toLowerCase() === "out of stock.") {
                return false;
            }

            // Next check if it has a price/is in stock.
            if (product.Offers && product.Offers.Listings[0] && product.Offers.Listings[0].Availability && (product.Offers.Listings[0].Availability.Type === "Now" || product.Offers.Listings[0].Availability.Type === "Preorderable" || product.Offers.Listings[0].Availability.Type === "Backorderable")) {
                return true;
            } 

            if (product.manualUpdates.availability.toLowerCase() === "in stock.") {
                return true;
            }
            
            return false;
        },

        productTitle: function(product) {
            if (product.manualUpdates.title) {
                return product.manualUpdates.title
            }

            return product.ItemInfo.Title.DisplayValue;
        },

        productImage: function(product) {
            if (product.manualUpdates.imageUrl) {
                return product.manualUpdates.imageUrl;
            }

            return product.Images.Primary.Large.URL;
        },

        productURL: function(product) {
            if (product.manualUpdates.url) {
                return product.manualUpdates.url;
            }

            return product.DetailPageURL;
        },

        productPrice: function(product) {
            let price = 0;

            if (product.Offers && product.Offers.Listings[0]) {
                price = product.Offers.Listings[0].Price.Savings ? product.Offers.Listings[0].Price.Amount + product.Offers.Listings[0].Price.Savings.Amount : product.Offers.Listings[0].Price.Amount;
            }

            if (product.manualUpdates.price) {
                price = Number(product.manualUpdates.price);
            }

            return price;
        },

        productSalePrice: function(product) {
            let salePrice = 0;

            if (product.Offers && product.Offers.Listings[0]) {
                salePrice = product.Offers.Listings[0].Price.Savings ? product.Offers.Listings[0].Price.Amount : 0;
            }

            if (product.manualUpdates.salePrice) {
                salePrice = Number(product.manualUpdates.salePrice);
            }

            return salePrice;
        },

        carouselSlide: function(x) {
            if (this.filteredFeed.length > 0) {
                this.scrollValue = Number(this.carouselTilesEl.getAttribute("scrollValue"));
                this.scrollValue += x;

                if (this.scrollValue < 0 && x < 0) {
                    this.scrollValue = 0;
                } else if (this.scrollValue > this.filteredFeed.length && x > 0) {
                    this.scrollValue = this.filteredFeed.length - 1;
                }

                this.carouselTilesEl.setAttribute("scrollValue", this.scrollValue);
                this.carouselTilesEl.scrollTo({
                    left: this.scrollValue * 278,
                    behavior: "smooth"
                });
            }
        },

        handleScroll: function() {
            window.clearTimeout(this.isScrolling);

            this.isScrolling = setTimeout(() => {
                if (this.carouselTilesEl.scrollLeft != this.scrollValue * 278) {
                    this.scrollValue = Math.round(this.carouselTilesEl.scrollLeft / 278);
                    this.carouselTilesEl.setAttribute("scrollValue", this.scrollValue);
                    this.carouselSlide(0);
                }
            }, 150);

            // Add or hide left chevron icon.
            if (this.carouselTilesEl.scrollLeft == 0 && !this.leftChevronHidden) {
                document.querySelector("#pButt").style.display = "none";
                this.leftChevronHidden = true;
            } else if (this.leftChevronHidden && this.carouselTilesEl != 0) {
                document.querySelector("#pButt").style.display = "block";
                this.leftChevronHidden = false;
            }
            // Add or hide right icon.
            else if (this.carouselTilesEl.scrollLeft >= (this.filteredFeed.length - 1) * 278 && !this.rightChevronHidden) {
                document.querySelector("#nButt").style.display = "none";
                this.rightChevronHidden = true;
            } else if (this.carouselTilesEl.scrollLeft < (this.filteredFeed.length - 1) * 278 && this.rightChevronHidden) {
                document.querySelector("#nButt").style.display = "block";
                this.rightChevronHidden = false;
            }
        }
    }
}
</script>

<style scoped>
    a:hover {
        text-decoration: none !important;
    }

    .adUnit {
        margin: 0 auto;
        border: gray 1px solid;
        width: 300px;
        height: 600px;
        padding: 10px;
        position: relative;
        box-sizing: border-box;
        background-repeat: no-repeat;
        background-position: center;
    }

    #pButt,
    #nButt {
        position: absolute;
        color: white;
        padding: 1px;
        font-size: 45px;
        background-color: rgba(0, 0, 0, .2);
        top: 375px;
        z-index: 2;
    }

    #nButt {
        right: -1px;
    }

    #pButt {
        left: -1px;
        display: none;
    }

    #pButt:hover,
    #nButt:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, .9);
    }

    #carouselHeader {
        display: block;
    }
    
    .carouselLogo {
        width: 280px;
        height: 30px;
        margin-top: 20px;
    }
    
    .carouselHero {
        width: 280px;
        height: 100px;
    }
    
    .carouselLogo img,
    .carouselHero img {
        width: 100%;
        height: auto;
    }

    #carouselTiles {
        display: inline-block;
        margin-top: 10px;
        white-space: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        width: 100%;
        height: 400px;
    }

    .productTile {
        width: 268px;
        height: 390px;
        display: inline-block;
        vertical-align: middle;
        margin: 5px;
        box-sizing: border-box;
        border-radius: 10px;
        overflow-y: hidden;
        box-shadow: 0 0 5px grey;
        padding: 0;
        background: white;
        color: #000000;
    }

    .productTile a {
        text-decoration: none;
        color: inherit;
    }

    .tileImage {
        width: 200px;
        height: 200px;
        margin: 0 auto;
        margin-top: 2px;
        display: flex;
    }

    .tileImage img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        align-self: center;
        margin: 0 auto;
    }

    .tileContent {
        height: 182px;
        overflow: hidden;
        padding: 0 5px;
        margin-top: 5px;
    }

    .productInfoCont {
        display: block;;
        margin-top: 2px;
        height: 100px;
    }

    .productDescription,
    .priceTag {
        width: calc(50% - 5px);
        margin: 0;
        overflow: hidden;
        white-space: normal;
        font-family: 'AmazonFont', Arial, sans-serif;
        position: relative;
        display: inline-block;
    }

    .productDescription {
        height: 100%;
        margin-right: 5px;
        font-size: 11px;
    }

    .priceTag {
        width: calc(100% - 10px);
        text-align: center;
        box-sizing: border-box;
        margin-left: 5px;
        color: black;
    }

    .priceSup {
        font-size: small;
    }

    .productPrice {
        font-size: 38px;
        line-height: 1;
        font-weight: 800;
        margin-bottom: 5px;
    }

    .callToAction {
        font-size: 14px;
        line-height: 28px;
        text-transform: none;
        color: #0F1111;
        background: #FFA41C;
        border: 1px solid #FF8F00;
        border-radius: 20px;
        box-shadow: 0 2px 5px 0 rgb(213 217 217 / 50%);
    }

    .callToAction:hover {
        background: #FA8900;
        border-color: #E3931E;
        box-shadow: 0 2px 5px 0 rgb(213 217 217 / 50%);
    }

    .productTitle {
        overflow: hidden;
        white-space: normal;
        font-size:18px;
        font-family: "AmazonFont", Arial, sans-serif;
        position: relative;
        width: 100%;
        height: 67px;
        text-align:center;
        vertical-align:top;
        line-height: 1.2;
    }

    .priceTag .prices {
        align-items: center;
        justify-content: space-evenly;
        font-size: 15px;
        margin-bottom: 5px;
    }

    .prices .originalPrice {
        position: relative;
    }
    
    .originalPrice, .discount {
        display: inline;
    }
    
    .discount{
        color: #B12704;
    }

    .prices .originalPrice::after {
        content:'';
        position:absolute;
        top:50%;
        right:0;
        width:100%;
        height:3px;
        background:#B12704;
        -webkit-transform:translateY(-50%) rotate(-15deg);
        transform:translateY(-50%) rotate(-15deg);
        -webkit-clip-path:unset;
        clip-path:unset;
        clip:unset;
        overflow:hidden;
        white-space:nowrap;
    }

    .productDisclaimer {
        font-family: 'AmazonFont', Arial, Helvetica, sans-serif;
        text-align: left;
        font-size: 10px;
        margin-left: 5px;
        margin-top: 3px;
    }

    .unitDisclaimer {
        font-family: 'AmazonFont', Arial, Helvetica, sans-serif;
        font-size: 11px;
        padding: 0 5px 5px;
        color: white;
        text-align: left;
    }
    
    #impTracker {
        display: none;
    }

    #loaderContainer {
        display: flex;
        margin-top: 10px;
        height: 390px;
    }

    .loader {
        border: 6px solid #f3f3f3;
        border-top: 6px solid #555;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        animation: spin 2s linear infinite;
        align-self: center;
        margin: 0 auto;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    /* Hide Scrollbar */
    #carouselTiles::-webkit-scrollbar {
        display: none;
    }

    #carouselTiles {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    /* Prevent Chevron Highlight */
    #pButt,
    #nButt {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    }
</style>