<template>
    <b-row>
        <b-col lg="1" cols="0">

        </b-col>
        <b-col lg="10" cols="12">
            <b-container>
                <!-- SELECTED -->
                <b-card no-body>
                    <b-card-body>
                        <div class="d-flex align-items">
                            <div class="d-flex align-items">
                                <b-icon-cart class="h2 chevronIcon" @click="selectedCollapse = !selectedCollapse" />
                                <b-badge>{{ $store.state.amazonFeed.products.length }}</b-badge>
                            </div>

                            <div class="ml-auto">
                                <h5 class="mb-0">{{ $store.state.amazonFeed.uid }}</h5>
                            </div>

                            <div class="ml-auto">
                                <div class="text-center">
                                    <b-button variant="success" @click="amazonNextStepThree" :disabled="$store.state.amazonFeed.products.length == 0">
                                        <div class="d-flex align-items">
                                            <div>Review</div>
                                            <div><b-icon-chevron-double-right /></div>
                                        </div>
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </b-card-body>

                    <b-collapse v-model="selectedCollapse">
                        <b-card-body class="border-top">
                            <b-card-title>Selected Products</b-card-title>
                            <b-card-sub-title class="mb-2">
                                <div v-if="filteredSelectedProducts.length == $store.state.amazonFeed.products.length">{{ $store.state.amazonFeed.products.length.toLocaleString() }} products</div>
                                <div v-else>{{ filteredSelectedProducts.length.toLocaleString() }} of {{ $store.state.amazonFeed.products.length.toLocaleString() }} products</div>
                            </b-card-sub-title>

                            <b-form-input v-model="selectedSearchText" placeholder="Search by product title or ASIN ID..." debounce="250"></b-form-input>

                            <div class="selectedProductsCont mt-3" v-if="$store.state.amazonFeed.products.length > 0">
                                <b-row>
                                    <b-col sm="4" v-for="(product, index) in filteredSelectedProducts" :key="index">
                                        <AmazonProductComponent :product="product" @editAmazonProduct="editAmazonProduct" @removeAmazonProduct="removeAmazonProduct" :class="index < 3 ? '' : 'mt-4'" :index="index" :selected="true" />
                                    </b-col>
                                </b-row>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>

                <!-- LOAD IN -->
                <b-card no-body class="mt-5 mb-5">
                    <b-card-body>
                        <b-card-title>Load in products by ASIN ID</b-card-title>
                        <div class="stepTwoCont">
                            <b-card-sub-title class="mb-2">{{ $store.state.loadedAmazonProducts.length.toLocaleString() }} products</b-card-sub-title>

                            <b-form @submit.prevent="searchProducts">
                                <b-input-group>
                                    <b-form-input placeholder="Input ASIN IDs seperated by a comma then hit enter..." v-model="asinIdInput" />
                                    <b-input-group-append>
                                        <b-button variant="outline-dark" type="submit" :disabled="(isLoadingProducts) || (asinIdInput.length === 0)">
                                            <span v-if="!isLoadingProducts">Load</span>
                                            <span v-else><b-spinner small /></span>
                                        </b-button>
                                    </b-input-group-append>
                                    <b-input-group-append>
                                        <b-button @click="addAllAmazonProducts" variant="outline-success" :disabled="filteredLoadedProducts.length == 0">Add All</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                                <b-alert variant="danger" :show="alertCountdown" dismissible @dismissed="alertCountdown = 0" @dismiss-count-down="alertCountdownChanged" class="mt-2">
                                    Error retrieving products. Please ensure ASIN is entered correctly, and is from the Amazon AU Marketplace.
                                </b-alert>
                            </b-form>

                            <div class="loadedProductsCont mt-3" v-if="filteredLoadedProducts.length > 0">
                                <b-row>
                                    <b-col sm="4" v-for="(product, index) in filteredLoadedProducts" :key="index">
                                        <AmazonProductComponent :product="product" :selected="false" :index="index" @addAmazonProduct="addAmazonProduct" @editAmazonProduct="editAmazonProduct" :class="index < 3 ? '' : 'mt-4'" />
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </b-card-body>
                </b-card>
            </b-container>
        </b-col>
        <b-col lg="1" cols="0">

        </b-col>
    </b-row>
</template>

<script>
import { functions } from '@/firebase'

import AmazonProductComponent from '@/components/Amazon/AmazonProductComponent'

export default {
    name: 'AmazonBuild',
    components: { AmazonProductComponent },
    data() {
        return {
            isLoadingProducts: false,
            asinIdInput: '',

            selectedSearchText: '',

            // Bootstrap
            selectedCollapse: false,
            dismissSecs: 10,
            alertCountdown: 0,
        }
    },

    created() {
        // this not available on beforeRouteEnter so check on created
        if (!this.$store.state.amazonFeed) {
            this.$router.replace("/feed/amazon");
        }
    },

    computed: {
        // TODO: Add ability to filter loaded products.
        filteredLoadedProducts() {
            return this.$store.state.loadedAmazonProducts;
        },

        // Filters selected products based on Amazon Title, Manual Updates Title, or ASIN.
        filteredSelectedProducts() {
            let temp = [];

            if (this.selectedSearchText) {
                temp = this.$store.state.amazonFeed.products.filter(product => {
                    if (product.ItemInfo && product.ItemInfo.Title && product.ItemInfo.Title.DisplayValue && product.ItemInfo.Title.DisplayValue.toLowerCase().includes(this.selectedSearchText.toLowerCase())) {
                        return product;
                    }   

                    if (product.manualUpdates && product.manualUpdates.title && product.manualUpdates.title.toLowerCase().includes(this.selectedSearchText.toLowerCase())) {
                        return product;
                    }

                    if (product.ASIN.toLowerCase().includes(this.selectedSearchText.toLowerCase())) {
                        return product;
                    }

                    return null;
                })
            } else {
                temp = JSON.parse(JSON.stringify(this.$store.state.amazonFeed.products));
            }

            return temp;
        }
    },

    methods: {
        // Add amazon product to selected and remove from loaded.
        addAmazonProduct(i) {
            let product = this.$store.state.loadedAmazonProducts[i];
            this.$store.commit("addAmazonProduct", product);
            this.$store.commit("removeAmazonProductFromLoaded", i);

        },

        // Add all loaded products to selected.
        addAllAmazonProducts() {
            while (this.$store.state.loadedAmazonProducts.length > 0) {
                this.addAmazonProduct(0);
            }
        },

        // Edit Amazon product.
        editAmazonProduct(updates, index) {
            this.$store.commit("editAmazonProduct", { index, updates })
        },

        // Remove Amazon product from selected and add to loaded.
        removeAmazonProduct(index) {
            let product = this.$store.state.amazonFeed.products[index];
            this.$store.commit("addAmazonProductToLoaded", product);
            this.$store.commit("removeAmazonProduct", index);
        },

        // Load in Amazon Product from Firebase/PAAPI.
        async searchProducts() {
            this.isLoadingProducts = true;
            const ASINIDs = this.asinIdInput.split(",");
        
            ASINIDs.forEach((asin, i) => {
                ASINIDs[i] = asin.trim();
            });

            const getAmazonProduct = functions.httpsCallable("getAmazonProduct");
            const result = await getAmazonProduct({ asinIds: ASINIDs }).catch(err => {
                console.error(err);
                this.alertCountdown = this.dismissSecs;
                this.isLoadingProducts = false;
            });
            
            console.log("SUCCESS:", result);
            this.isLoadingProducts = false;
            result.data.items.forEach(item => {
                let temp = item;
                temp.manualUpdates = {
                    title: '',
                    price: '',
                    salePrice: '',
                    imageUrl: '',
                    url: '',
                    availability: '',
                }

                this.$store.commit("addAmazonProductToLoaded", temp);
            })

            this.asinIdInput = "";
            
        },

        amazonNextStepThree() {
            this.$router.push("/feed/amazon/review");
        },

        // Update error countdown timer.
        alertCountdownChanged(alertCountdown) {
            this.alertCountdown = alertCountdown;
        }
    }
}
</script>

<style scoped>
    .chevronIcon:hover {
        cursor: pointer;
    }

    .align-items {
        align-items: center !important;
    }
</style>