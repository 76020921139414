<template>
    <!-- 
        Standard Review points to the URL: https://feedbuilder.newsconnect.com.au/feed/standard/review
    -->
    <b-row>
        <b-col cols="3">
            <b-card no-body class="mb-5">
                <b-card-body>
                        <h5>Filter By</h5>
                        <!-- ON SALE FILTER -->
                        <div v-if="availableSale" class="mt-3">
                            <h6>On Sale</h6>
                            <b-form-checkbox v-model="selectedSale">Sale</b-form-checkbox>
                            <b-form-checkbox v-model="selectedNoSale">No Sale</b-form-checkbox>
                        </div>
                        <!-- BRAND FILTERS -->
                        <div v-if="availableBrands.length > 0" class="mt-3">
                            <div class="d-flex mb-1 align-items" align-v="center">
                                <div>
                                    <h6 class="m-0">Brand</h6>
                                </div>
                                <div class="ml-auto" v-if="selectedBrands.length > 0">
                                    <b-icon-x-circle class="chevronIcon" @click="selectedBrands = [];brandSearchText = ''" variant="danger" font-scale=".66" />
                                </div>
                            </div>
                            <div class="brandsCont">
                                <div v-if="availableBrands.length > 10">
                                    <b-form-input type="text" v-model="brandSearchText" size="sm" placeholder="Filter brands..."  debounce="250" />
                                </div>
                                <b-form-checkbox-group class="mt-2" v-model="selectedBrands" :options="filteredAvailableBrands.slice(0, 100)" stacked></b-form-checkbox-group>
                            </div>
                        </div>
                        <!-- GOOGLE PRODUCT CATEGORY FILTERS -->
                        <div v-if="availableGoogleProductCategories.length > 0" class="mt-3">
                            <div class="d-flex mb-1 align-items">
                                <div>
                                    <h6 class="m-0">Google Product Category</h6>
                                </div>
                                <div class="ml-auto" v-if="selectedGoogleProductCategories.length > 0">
                                    <b-icon-x-circle class="chevronIcon" @click="selectedGoogleProductCategories = [];googleProductCategorySearchText = ''" variant="danger" font-scale=".66" />
                                </div>
                            </div>
                            <div class="googleProductCategoriesCont">
                                <div v-if="availableGoogleProductCategories.length > 10">
                                    <b-form-input type="text" v-model="googleProductCategorySearchText" size="sm" placeholder="Filter product categories..." debounce="250" />
                                </div>
                                <b-form-checkbox-group class="mt-2" v-model="selectedGoogleProductCategories" :options="filteredAvailableGoogleProductCategories.slice(0, 100)" stacked></b-form-checkbox-group>
                            </div>
                        </div>
                        <!-- PRODUCT TYPE FILTERS -->
                        <div v-if="availableProductTypes.length > 0" class="mt-3">
                            <div class="d-flex mb-1 align-items">
                                <div>
                                    <h6 class="m-0">Product Type</h6>
                                </div>
                                <div class="ml-auto" v-if="selectedProductTypes.length > 0">
                                    <b-icon-x-circle class="chevronIcon" @click="selectedProductTypes = [];productTypeSearchText = ''" variant="danger" font-scale=".66" />
                                </div>
                            </div>
                            <div class="productTypesCont">
                                <div v-if="availableProductTypes.length > 10">
                                    <b-form-input type="text" v-model="productTypeSearchText" size="sm" placeholder="Filter product types..." debounce="250" />
                                </div>
                                <b-form-checkbox-group class="mt-2" v-model="selectedProductTypes" :options="filteredAvailableProductTypes.slice(0, 100)" stacked></b-form-checkbox-group>
                            </div>
                        </div>
                        <!-- AVAILABILITY FILTERS -->
                        <div v-if="availableAvailability.length > 0" class="mt-3">
                            <h6>Availability</h6>
                            <b-form-checkbox-group v-model="selectedAvailability" :options="availableAvailability" stacked></b-form-checkbox-group>
                        </div>
                        <!-- CONDITION FILTERS -->
                        <div v-if="availableCondition.length > 0" class="mt-3">
                            <h6>Condition</h6>
                            <b-form-checkbox-group v-model="selectedCondition" :options="availableCondition" stacked></b-form-checkbox-group>
                        </div>
                    </b-card-body>
            </b-card>
        </b-col>
        <b-col cols="9">
            <b-card no-body class="mb-5">
                <b-card-body>
                    <b-card-title>
                        <div class="d-flex">
                            <div>Review</div>
                            <div class="ml-auto">
                                <div class="text-center">
                                    <b-button variant="success" @click="nextStepFour" :disabled="isLoading">
                                        <div class="d-flex align-items" v-if="!isLoading">
                                            <div>Upload</div>
                                            <div><b-icon-chevron-double-right /></div>
                                        </div>
                                        <div v-else>
                                            <b-spinner small />
                                        </div>
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </b-card-title>
                    <b-card-sub-title class="mb-2">
                        <div v-if="filteredSelectedProducts.length == $store.state.standardFeed.products.length">{{ $store.state.standardFeed.products.length.toLocaleString() }} products</div>
                        <div v-else>{{ filteredSelectedProducts.length.toLocaleString() }} of {{ $store.state.standardFeed.products.length.toLocaleString() }} products</div>
                    </b-card-sub-title>

                    <b-form-input placeholder="Search by product title..." v-model="selectedSearchText" debounce="250"></b-form-input>

                    <div class="text-center mt-2" v-if="filteredSelectedProducts.length < $store.state.standardFeed.products.length">
                        <b-button @click="removeAll" variant="outline-danger">Remove All</b-button>
                    </div>

                    <div class="selectedProductsCont mt-3" v-if="filteredSelectedProducts.length > 0">
                        <b-row>
                            <b-col sm="4" v-for="(product, index) in filteredSelectedProducts.slice(0, selectedLoadAmount)" :key="index">
                                <ProductComponent :product="product" @editProduct="editProduct" @removeProduct="removeProduct" :class="index < 3 ? '' : 'mt-4'" :selected="true" />
                            </b-col>
                        </b-row>

                        <div class="mt-4 mb-3 text-center">
                            <b-button @click="selectedLoadAmount -= 30" v-if="selectedLoadAmount > 30 && filteredSelectedProducts.length > 30" variant="outline-danger" class="mr-2">Load Less</b-button>
                            <b-button @click="selectedLoadAmount += 30" v-if="filteredSelectedProducts.length > selectedLoadAmount" variant="outline-dark">Load More</b-button>
                        </div>
                    </div>
                </b-card-body>
            </b-card>

            <b-modal v-model="confirmUpdateModalActive" title="Please Confirm"> 
                <div>
                    <div>
                        <div><strong>WARNING: Proceeding will push any changes you have made live.</strong></div>
                        <div class="mt-1">If you have any live campaigns attached to this feed, they will be updated immediately.</div>
                        <div class="mt-1">Please ensure all data provided is accurate.</div>
                    </div>
                    <div class="mt-4">
                        <CatalogueComponent :feed="$store.state.standardFeed.products" />
                    </div>
                </div>

                <template #modal-footer>
                    <div>
                        <b-button size="sm" variant="outline-dark" @click="confirmUpdateModalActive = false">Go Back</b-button>
                        <b-button size="sm" variant="danger" @click="editFeed" class="ml-2">Upload</b-button>
                    </div>
                </template>
            </b-modal>
        </b-col>
    </b-row>
</template>

<script>
import ProductComponent from '@/components/Standard/ProductComponent.vue'
import CatalogueComponent from "@/components/GoogleMerchantCenter/CatalogueComponent.vue";

import { functions } from '@/firebase'

export default {
    name: 'StandardAPIReview',
    components: { ProductComponent, CatalogueComponent },
    data() {
        return {
            isLoading: false,
            selectedSearchText: '',
            selectedLoadAmount: 30,

            // Filters:
            selectedSale: false,
            availableSale: false,
            selectedNoSale: false,
            selectedBrands: [],
            availableBrands: [],
            brandSearchText: '',
            selectedGoogleProductCategories: [],
            availableGoogleProductCategories: [],
            googleProductCategorySearchText: '',
            selectedProductTypes: [],
            availableProductTypes: [],
            productTypeSearchText: '',
            selectedAvailability: [],
            availableAvailability: [],
            selectedCondition: [],
            availableCondition: [],

            // Error Handling:
            dismissSecs: 10,
            alertCountdown: 0,
            errorMessage: '',

            // Bootstrap
            confirmUpdateModalActive: false,
        }
    },

    created() {
        if (!this.$store.state.standardFeed || (this.$store.state.standardFeed.products.length == 0 && this.$store.state.loadedStandardProducts.length == 0)) {
            this.$router.replace("/feed/standard");
        } else if (this.$store.state.standardFeed.products.length == 0) {
            this.$router.replace("/feed/standard/build");
        }

        this.setFilters();
    },

    computed: {
        // Returns all selected products after the various filters have been applied to them.
        filteredSelectedProducts() {
            let temp = [];

            if (this.selectedSearchText) {
                temp = this.$store.state.standardFeed.products.filter(product => {
                    if (product.title) {
                        return product.title.toLowerCase().includes(this.selectedSearchText.toLowerCase());
                    }

                    return null;
                });
            } else {
                temp = JSON.parse(JSON.stringify(this.$store.state.standardFeed.products));
            }

            if (this.selectedSale && !this.selectedNoSale) {
                temp = temp.filter(product => {
                    if (product.sale_price && product.sale_price < product.price) {
                        return true
                    }

                    return null;
                })
            } else if (this.selectedNoSale && !this.selectedSale) {
                temp = temp.filter(product => {
                    if (!product.sale_price || product.sale_price >= product.price) {
                        return true;
                    }

                    return null;
                })
            }

            if (this.selectedBrands.length > 0) {
                temp = temp.filter(product => {
                    if (product.brand) {
                        return this.selectedBrands.some(value => product.brand == value);
                    }

                    return null;
                })
            }

            if (this.selectedGoogleProductCategories.length > 0) {
                temp = temp.filter(product => {
                    if (product.google_product_category) {
                        return this.selectedGoogleProductCategories.some(value => product.google_product_category == value);
                    }

                    return null;
                })
            }

            if (this.selectedProductTypes.length > 0) {
                temp = temp.filter(product => {
                    if (product.product_type) {
                        return this.selectedProductTypes.some(value => product.product_type == value);
                    }

                    return null;
                })
            }

            if (this.selectedAvailability.length > 0) {
                temp = temp.filter(product => {
                    if (product.availability) {
                        return this.selectedAvailability.some(value => product.availability == value);
                    }

                    return null;
                })
            }

            if (this.selectedCondition.length > 0) {
                temp = temp.filter(product => {
                    if (product.condition) {
                        return this.selectedCondition.some(value => product.condition == value);
                    }

                    return null;
                })
            }

            return temp;
        },

        // Returns available brand filters, filtered with the search text.
        filteredAvailableBrands() {
            if (this.brandSearchText) {
                return this.availableBrands.filter(brand => {
                    return brand.toLowerCase().includes(this.brandSearchText.toLowerCase());
                })
            } else {
                return this.availableBrands
            }
        },

        // Returns available google product category filters, filtered with the search text.
        filteredAvailableGoogleProductCategories() {
            if (this.googleProductCategorySearchText) {
                return this.availableGoogleProductCategories.filter(category => {
                    return category.toLowerCase().includes(this.googleProductCategorySearchText.toLowerCase());
                })
            } else {
                return this.availableGoogleProductCategories;
            }
        },

        // Returns available product type filters, filtered with the search text.
        filteredAvailableProductTypes() {
            if (this.productTypeSearchText) {
                return this.availableProductTypes.filter(type => {
                    return type.toLowerCase().includes(this.productTypeSearchText.toLowerCase());
                })
            } else {
                return this.availableProductTypes;
            }
        },
    },

    methods: {
        // Creates the new feed in firebase if new, otherwise opens the edit popup.
        async nextStepFour() {
            if (this.$store.state.standardFeed.isCreating) {
                this.isLoading = true;
                console.log("Creating Standard API Feed");
                const createAPIFeed = functions.httpsCallable("createAPIFeed");
                console.log(this.$store.state.standardFeed);
                const result = await createAPIFeed({ feed: this.$store.state.standardFeed, userEmail: this.$store.state.userProfile.data.email, url: this.$store.state.url}).catch(err => {
                    console.error(err);
                    this.alertCountdown = this.dismissSecs;
                    this.isLoading = false;
                });

                if (result && result.data && result.data.success) {
                    console.log("Successfully created:", result);
                    this.$store.commit("resetStandardChangelog");
                    this.$router.push("/feed/standard/preview");
                } else {
                    console.error(result);
                    this.alertCountdown = this.dismissSecs;
                    this.isLoading = false;
                }
            } else {
                this.confirmUpdateModalActive = true;
            }
        },

        // Edits the feed in Firebase.
        async editFeed() {
            this.confirmUpdateModalActive = false;
            this.isLoading = true;

            const updateFeed = functions.httpsCallable("updateFeed");
            const result = await updateFeed({ feed: this.$store.state.standardFeed, userEmail: this.$store.state.userProfile.data.email, password: this.$store.state.standardFeed.password, changelog: this.$store.state.standardChangelog }).catch(() => {
                return null;
            });

            if (result && result.data && result.data.success) {
                console.log("Successfully updated", result);
                this.$store.commit("resetStandardChangelog");
                this.$router.push("/feed/standard/preview");
            } else {
                console.error(result);
                this.alertCountdown = this.dismissSecs;
                this.isLoading = false;
            }
        },

        // Edits a selected product.
        editProduct(id, newProductData) {
            const index = this.$store.state.standardFeed.products.findIndex(x => x.id == id);

            // Set this index to be equal to the new product data.
            // Must be set this way to keep Vue reactive.
            // this.selectedProducts[index] = newProductData;
            this.$store.commit("editStandardProduct", { index, newProductData });

            this.$nextTick(() => {
                // Edit available filters.
                if (!this.availableSale && this.$store.state.standardFeed.products[index].sale_price) {
                    this.availableSale = true;
                }

                if (this.$store.state.standardFeed.products[index].brand && !this.availableBrands.includes(this.$store.state.standardFeed.products[index].brand) && this.$store.state.standardFeed.products[index].brand.trim() !== "") {
                    this.availableBrands.push(this.$store.state.standardFeed.products[index].brand);
                }
            })
        },

        // Removes a product from selected and adds to loaded.
        removeProduct(id) {
            const index = this.$store.state.standardFeed.products.findIndex(x => x.id == id);

            this.$store.commit("addStandardProductToLoaded", this.$store.state.standardFeed.products[index]);
            this.$store.commit("removeStandardProduct", index);
        },

        // Removes all filtered froducts.
        removeAll() {
            this.$bvModal.msgBoxConfirm('Are you sure you want to remove all ' + this.filteredSelectedProducts.length + ' products from your feed?', {
                title: 'Please Confirm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'I\'m Sure',
                cancelTitle: 'Cancel',
                centered: true,
                footerClass: 'p-2'
            })
            .then(value => {
                if (value) {
                    this.filteredSelectedProducts.forEach(product => {
                        this.removeProduct(product.id);
                    })
                }
            })
            .catch(e => {
                console.error(e);
            })
        },

        // Resets the available filters if a product is removed.
        setFilters() {
            this.$store.state.standardFeed.products.forEach(item => {
                // Brand
                if (item.brand && !this.availableBrands.includes(item.brand) && item.brand.trim() !== "") {
                    this.availableBrands.push(item.brand);
                }

                // Google Product Category
                if (item.google_product_category && !this.availableGoogleProductCategories.includes(item.google_product_category) && item.google_product_category.trim() !== "") {
                    this.availableGoogleProductCategories.push(item.google_product_category);
                }

                // Product Type
                if (item.product_type && !this.availableProductTypes.includes(item.product_type) && item.product_type.trim() !== "") {
                    this.availableProductTypes.push(item.product_type);
                }

                // Availability
                if (item.availability && !this.availableAvailability.includes(item.availability) && item.availability.trim() !== "") {
                    this.availableAvailability.push(item.availability);
                }

                // Condition
                if (item.condition && !this.availableCondition.includes(item.condition) && item.condition.trim() !== "") {
                    this.availableCondition.push(item.condition);
                }

                // Sale
                if (!this.availableSale && item.sale_price && item.sale_price < item.price) {
                    this.availableSale = true;
                }
            })
        }
    }
}
</script>

<style scoped>
    .align-items {
        align-items: center !important;
    }
</style>