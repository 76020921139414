import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCCgy0Zety-NjgD61EEtvxwSy6stoDtM_I",
    authDomain: "nau-ndm-prod-catalogueconnect.firebaseapp.com",
    databaseURL: "https://nau-ndm-prod-catalogueconnect-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "nau-ndm-prod-catalogueconnect",
    storageBucket: "nau-ndm-prod-catalogueconnect.appspot.com",
    messagingSenderId: "669749693642",
    appId: "1:669749693642:web:c85f4cab31345003863833"
};

firebase.initializeApp(firebaseConfig);

const functions = firebase.app().functions("australia-southeast1");
const auth = firebase.auth();

const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            unsubscribe();
            resolve(user)
        }, reject);
    })
};

export { functions, auth, getCurrentUser }
