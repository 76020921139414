export const isValidProduct = product => {
    if (!product.id) {
        console.warn ("No product ID")
        return false;
    }
    
    if (!product.title) {
        console.warn ("No product title for product ID:", product.id)
        return false;
    }

    if ((!product.price && product.price !== 0) || isNaN(product.price)) {
        console.warn ("No product price for product ID:", product.id)
        return false;
    }

    if (!product.image_link) {
        console.warn ("No product image link for product ID:", product.id)
        return false;
    }

    if (!product.link) {
        console.warn ("No product clickthrough for product ID:", product.id)
        return false;
    }

    if (product.sale_price && isNaN(product.sale_price)) {
        console.warn ("Not a valid sale price for product ID:", product.id, product.sale_price);
        return false;
    }
    
    return true;
}