<template>
    <b-row>
        <b-col lg="0" cols="1">

        </b-col>
        <b-col lg="10" cols="12">
            <b-card no-body class="mb-5">
                <b-card-body>
                    <b-card-title>
                        <div class="d-flex align-items">
                            <div>Review</div>
                            <div class="ml-auto">
                                <h5 class="mb-0">{{ $store.state.amazonFeed.uid }}</h5>
                            </div>
                            <div class="ml-auto">
                                <div class="text-center">
                                    <b-button variant="success" @click="amazonNextStepFour" :disabled="isLoading">
                                        <div class="d-flex align-items" v-if="!isLoading">
                                            <div>Upload</div>
                                            <div><b-icon-chevron-double-right /></div>
                                        </div>
                                        <div v-else>
                                            <b-spinner small />
                                        </div>
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </b-card-title>

                    <b-card-sub-title class="mb-2">
                        <div v-if="filteredProducts.length == $store.state.amazonFeed.products.length">{{ $store.state.amazonFeed.products.length.toLocaleString() }} products</div>
                        <div v-else>{{ filteredProducts.length.toLocaleString() }} of {{ $store.state.amazonFeed.products.length.toLocaleString() }} products</div>
                    </b-card-sub-title>

                    <b-form-input placeholder="Search by product title or ASIN ID..." v-model="selectedSearchText" debounce="250"></b-form-input>

                    <div class="mt-2">
                        <b-alert variant="danger" fade :show="alertCountdown" dismissible @dismissed="alertCountdown = 0" @dismiss-count-down="alertCountdownChanged">
                            Error uploading. Please try again later.
                        </b-alert>
                    </div>

                    <div class="selectedProductsCont mt-3" v-if="filteredProducts.length > 0">
                        <b-row>
                            <b-col sm="4" v-for="(product, index) in filteredProducts" :key="index">
                                <AmazonProductComponent :product="product" :index="index" :selected="true" @editAmazonProduct="editAmazonProduct" @removeAmazonProduct="removeAmazonProduct" :class="index < 3 ? '' : 'mt-4'" />
                            </b-col>
                        </b-row>
                    </div>
                </b-card-body>
            </b-card>

            <b-modal v-model="confirmUpdateModalActive" title="Please Confirm"> 
                <div>
                    <div>
                        <div>WARNING: Proceeding will push any changes you have made live.</div>
                        <div class="mt-1">If you have any live campaigns attached to this feed, they will be updated immediately.</div>
                        <div class="mt-1">Please ensure all data provided is accurate.</div>
                    </div>
                    <div class="mt-4">
                        <AmazonCatalogueComponent :feed="$store.state.amazonFeed" />
                    </div>
                </div>

                <template #modal-footer>
                    <div>
                        <b-button size="sm" variant="outline-dark" @click="confirmUpdateModalActive = false">Go Back</b-button>
                        <b-button size="sm" variant="danger" @click="uploadAmazonFeed" class="ml-2">Upload</b-button>
                    </div>
                </template>
            </b-modal>
        </b-col>
        <b-col lg="0" cols="1">
 
        </b-col>
    </b-row>
</template>

<script>
import AmazonProductComponent from '@/components/Amazon/AmazonProductComponent'
import AmazonCatalogueComponent from '@/components/Amazon/AmazonCatalogueComponent'

import { functions } from '@/firebase'

export default {
    name: 'AmazonReview',
    components: { AmazonProductComponent, AmazonCatalogueComponent },
    data() {
        return {
            selectedSearchText: '',
            isLoading: false,

            // Error Handling
            dismissSecs: 10,
            alertCountdown: 0,
            errorMessage: '',

            // Modal
            confirmUpdateModalActive: false,
        }
    },

    created() {
        // this not available on beforeRouteEnter so check on created
        if (!this.$store.state.amazonFeed) {
            this.$router.replace("/feed/amazon");
        }
    },

    computed: {
        // Returns all selected products after the various filters have been applied to them.
        filteredProducts() {
            let temp = [];

            if (this.selectedSearchText) {
                temp = this.$store.state.amazonFeed.products.filter(product => {
                    if (product.ItemInfo && product.ItemInfo.Title && product.ItemInfo.Title.DisplayValue && product.ItemInfo.Title.DisplayValue.toLowerCase().includes(this.selectedSearchText.toLowerCase())) {
                        return product;
                    }   

                    if (product.manualUpdates && product.manualUpdates.title && product.manualUpdates.title.toLowerCase().includes(this.selectedSearchText.toLowerCase())) {
                        return product;
                    }

                    if (product.ASIN.toLowerCase().includes(this.selectedSearchText.toLowerCase())) {
                        return product;
                    }

                    return null;
                })
            } else {
                temp = JSON.parse(JSON.stringify(this.$store.state.amazonFeed.products));
            }

            return temp;
        }
    },

    methods: {
        // Creates the new feed in firebase if new, otherwise opens the edit popup.
        async amazonNextStepFour() {
            if (this.$store.state.amazonFeed.isCreating) {
                this.isLoading = true;
                console.log("CREATING AMAZON FEED");
                const createFeed = functions.httpsCallable("createFeed");
                const result = await createFeed({ feed: this.$store.state.amazonFeed, userEmail: this.$store.state.userProfile.data.email })
                .catch(err => {
                    console.error(err);
                    this.alertCountdown = this.dismissSecs;
                    this.isLoading = false;
                });

                if (result.data.success) {
                    console.log("Successfully created:", result);
                    this.$store.commit("resetAmazonChangelog");
                    this.$router.push("/feed/amazon/preview");
                } else {
                    console.error(result);
                    this.alertCountdown = this.dismissSecs;
                    this.isLoading = false;
                }
            } else {
                this.confirmUpdateModalActive = true;
            }
        },

        // Edits the feed in Firebase.
        async uploadAmazonFeed() {
            this.confirmUpdateModalActive = false;
            this.isLoading = true;

            const updateFeed = functions.httpsCallable("updateFeed");
            const result = await updateFeed({ feed: this.$store.state.amazonFeed, userEmail: this.$store.state.userProfile.data.email, password: this.$store.state.amazonFeed.password, changelog: this.$store.state.amazonChangelog }).catch(() => {
                return null;
            })

            if (result && result.data && result.data.success) {
                console.log("Successfully updated", result);
                this.$store.commit("resetAmazonChangelog");
                this.$router.push("/feed/amazon/preview");
            } else {
                console.error(result);
                this.alertCountdown = this.dismissSecs;
                this.isLoading = false;
            }

        },

        // Edits a selected product.
        editAmazonProduct(updates, index) {
            this.$store.commit("editAmazonProduct", { index, updates })
        },

        // Removes a product
        removeAmazonProduct(index) {
            let product = this.$store.state.amazonFeed.products[index];
            this.$store.commit("addAmazonProductToLoaded", product);
            this.$store.commit("removeAmazonProduct", index);
        },

        // Updates error countdown timer.
        alertCountdownChanged(alertCountdown) {
            this.alertCountdown = alertCountdown;
        }
    }
}
</script>

<style scoped>
    .align-items {
        align-items: center !important;
    }
</style>