<template>
    <b-row>
        <b-col lg="3" cols="0">

        </b-col>
        <b-col lg="6" cols="12">
            <b-card no-body>
                <b-card-body>
                    <b-card-title>Feed uploaded successfully!</b-card-title>
                    <b-card-sub-title>Feed ID: {{ $store.state.amazonFeed.uid }}</b-card-sub-title>

                    <div class="mt-2">
                        <p>Save the above feed ID and pass on to your account manager.</p>
                        <p>In the meantime, please see a preview of the unit below. You can upload a logo, hero and/or background image to see what your final unit may look like.</p>
                        <p>Note: Changes to logo, hero, and/or background image will not be uploaded/reflected in your live unit.</p>
                    </div>

                </b-card-body>
            </b-card>

            <b-card no-body class="mt-5">
                <b-card-body>
                    <b-card-title>Preview</b-card-title>

                    <div class="mt-1">
                        <div class="mt-3">
                            <label for="backgroundUpload">Background Image (300x600)</label>
                            <b-input-group>
                                <b-form-file id="backgroundUpload" v-model="backgroundImageFile" size="sm" @change="buildBackgroundURL" />
                                <b-input-group-append>
                                    <b-button variant="danger" size="sm" @click="clearBackground" :disabled="backgroundImageFile === null && backgroundImageURL === ''"><b-icon-x /></b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                    </div>
                    <div class="mt-5">
                        <AmazonCatalogueComponent :feed="$store.state.amazonFeed" :background="backgroundImageURL" />
                    </div>
                </b-card-body>
            </b-card>
        </b-col>
        <b-col lg="3" cols="0">

        </b-col>
    </b-row>    
</template>

<script>
import AmazonCatalogueComponent from '@/components/Amazon/AmazonCatalogueComponent'

export default {
    name: 'AmazonPreview',
    components: { AmazonCatalogueComponent },
    data() {
        return {
            backgroundImageFile: null,
            backgroundImageURL: '',
        }
    },
    
    created() {
        // "this" not available on beforeRouteEnter so check on created
        if (!this.$store.state.amazonFeed) {
            this.$router.replace("/feed/amazon");
        }
    },

    methods: {
        clearBackground() {
            this.backgroundImageFile = null;
            this.backgroundImageURL = '';
        },

        buildBackgroundURL(e) {
            if (e.target.files[0]) {
                this.backgroundImageURL = URL.createObjectURL(e.target.files[0]);
            }
        }
    }
}
</script>