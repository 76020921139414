<template>
    <!-- 
        Standard Build points to the URL: https://feedbuilder.newsconnect.com.au/feed/standard/build
     -->
    <b-row>
        <b-col sm="3">
            <b-container>
                <b-card no-body>
                    <b-card-body>
                        <h5>Filter By</h5>
                        <!-- ON SALE FILTER -->
                        <div v-if="availableSale" class="mt-3">
                            <h6>On Sale</h6>
                            <b-form-checkbox v-model="selectedSale">Sale</b-form-checkbox>
                            <b-form-checkbox v-model="selectedNoSale">No Sale</b-form-checkbox>
                        </div>
                        <!-- BRAND FILTERS -->
                        <div v-if="availableBrands.length > 0" class="mt-3">
                            <div class="d-flex mb-1 align-items" align-v="center">
                                <div>
                                    <h6 class="m-0">Brand</h6>
                                </div>
                                <div class="ml-auto" v-if="selectedBrands.length > 0">
                                    <b-icon-x-circle class="hover-icon" @click="selectedBrands = [];brandSearchText = ''" variant="danger" font-scale=".66" />
                                </div>
                            </div>
                            <div class="brandsCont">
                                <div v-if="availableBrands.length > 10">
                                    <b-form-input type="text" v-model="brandSearchText" size="sm" placeholder="Filter brands..."  debounce="250" />
                                </div>
                                <b-form-checkbox-group class="mt-2" v-model="selectedBrands" :options="filteredAvailableBrands.slice(0, 100)" stacked></b-form-checkbox-group>
                            </div>
                        </div>
                        <!-- GOOGLE PRODUCT CATEGORY FILTERS -->
                        <div v-if="availableGoogleProductCategories.length > 0" class="mt-3">
                            <div class="d-flex mb-1 align-items">
                                <div>
                                    <h6 class="m-0">Google Product Category</h6>
                                </div>
                                <div class="ml-auto" v-if="selectedGoogleProductCategories.length > 0">
                                    <b-icon-x-circle class="hover-icon" @click="selectedGoogleProductCategories = [];googleProductCategorySearchText = ''" variant="danger" font-scale=".66" />
                                </div>
                            </div>
                            <div class="googleProductCategoriesCont">
                                <div v-if="availableGoogleProductCategories.length > 10">
                                    <b-form-input type="text" v-model="googleProductCategorySearchText" size="sm" placeholder="Filter product categories..." debounce="250" />
                                </div>
                                <b-form-checkbox-group class="mt-2" v-model="selectedGoogleProductCategories" :options="filteredAvailableGoogleProductCategories.slice(0, 100)" stacked></b-form-checkbox-group>
                            </div>
                        </div>
                        <!-- PRODUCT TYPE FILTERS -->
                        <div v-if="availableProductTypes.length > 0" class="mt-3">
                            <div class="d-flex mb-1 align-items">
                                <div>
                                    <h6 class="m-0">Product Type</h6>
                                </div>
                                <div class="ml-auto" v-if="selectedProductTypes.length > 0">
                                    <b-icon-x-circle class="hover-icon" @click="selectedProductTypes = [];productTypeSearchText = ''" variant="danger" font-scale=".66" />
                                </div>
                            </div>
                            <div class="productTypesCont">
                                <div v-if="availableProductTypes.length > 10">
                                    <b-form-input type="text" v-model="productTypeSearchText" size="sm" placeholder="Filter product types..." debounce="250" />
                                </div>
                                <b-form-checkbox-group class="mt-2" v-model="selectedProductTypes" :options="filteredAvailableProductTypes.slice(0, 100)" stacked></b-form-checkbox-group>
                            </div>
                        </div>
                        <!-- AVAILABILITY FILTERS -->
                        <div v-if="availableAvailability.length > 0" class="mt-3">
                            <h6>Availability</h6>
                            <b-form-checkbox-group v-model="selectedAvailability" :options="availableAvailability" stacked></b-form-checkbox-group>
                        </div>
                        <!-- CONDITION FILTERS -->
                        <div v-if="availableCondition.length > 0" class="mt-3">
                            <h6>Condition</h6>
                            <b-form-checkbox-group v-model="selectedCondition" :options="availableCondition" stacked></b-form-checkbox-group>
                        </div>
                    </b-card-body>
                </b-card>
            </b-container>
        </b-col>
        <b-col sm="9">
            <b-container>
                <b-card no-body>
                    <b-card-body>
                        <div class="d-flex align-items">
                            <div class="d-flex align-items">
                                <b-icon-cart class="h2 hover-icon" @click="selectedCollapse = !selectedCollapse" />
                                <b-badge>{{ $store.state.standardFeed.products.length }}</b-badge>
                            </div>

                            <div class="ml-auto">
                                <h5>{{ $store.state.standardFeed.uid }}</h5> 
                            </div>

                            <div class="ml-auto">
                                <div class="text-center">
                                    <b-button variant="success" @click="nextStepThree" :disabled="$store.state.standardFeed.products.length > this.maximumProducts || $store.state.standardFeed.products.length == 0">
                                        <div class="d-flex align-items">
                                            <div>Review</div>
                                            <div><b-icon-chevron-double-right /></div>
                                        </div>
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </b-card-body>

                    <b-collapse v-model="selectedCollapse">
                        <b-card-body class="border-top">
                            <b-card-title>Selected Products</b-card-title>
                            <b-card-sub-title class="mb-2">{{ filteredSelectedProducts.length.toLocaleString() }} products</b-card-sub-title>

                            <b-form-input placeholder="Search by product title..." v-model="selectedSearchText" debounce="250"></b-form-input>

                            <div class="selectedProductsCont mt-3" v-if="filteredSelectedProducts.length > 0">
                                <b-row>
                                    <b-col sm="4" v-for="(product, index) in filteredSelectedProducts.slice(0, selectedLoadAmount)" :key="index">
                                        <ProductComponent :product="product" @addProduct="addProduct" @editProduct="editProduct" @removeProduct="removeProduct" :class="index < 3 ? '' : 'mt-4'" :selected="true" />
                                    </b-col>
                                </b-row>

                                <div class="mt-4 mb-3 text-center">
                                    <b-button @click="selectedLoadAmount -= 30" v-if="selectedLoadAmount > 30 && filteredSelectedProducts.length > 30" variant="outline-danger" class="mr-2">Load Less</b-button>
                                    <b-button @click="selectedLoadAmount += 30" v-if="filteredSelectedProducts.length > selectedLoadAmount" variant="outline-dark">Load More</b-button>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>

                <b-card no-body class="mt-5 mb-5">
                    <b-card-body>
                        <b-card-title class="d-flex">
                            <div>Select which products to use</div>
                            <div class="ml-auto d-flex">
                                <b-icon-chevron-bar-contract class="hover-icon" v-if="inputCollapse" @click="inputCollapse = !inputCollapse" />
                                <b-icon-chevron-bar-expand class="hover-icon" v-else @click="inputCollapse = !inputCollapse" />
                                <b-dropdown variant="outline" menu-class="add-products-dropdown-menu" class="add-products-dropdown-icon d-flex align-items-center">
                                    <b-dropdown-item link-class="px-3" @click="addProductModal = true;">
                                        <b-icon-plus link-class="mr-1" /> Add a Product
                                    </b-dropdown-item>
                                    <b-dropdown-item link-class="px-3" @click="addFeedModal = true;">
                                        <b-icon-cart-plus class="mr-1" /> Add a Feed
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </b-card-title>
                        <b-card-sub-title class="mb-2">
                            <div v-if="filteredInputProducts.length == $store.state.loadedStandardProducts.length">{{ $store.state.loadedStandardProducts.length.toLocaleString() }} products</div>
                            <div v-else>{{ filteredInputProducts.length.toLocaleString() }} of {{ $store.state.loadedStandardProducts.length.toLocaleString() }} products</div>
                            
                        </b-card-sub-title>
                        <div>
                            <b-collapse visible v-model="inputCollapse" class="mb-2">
                                <b-form-input placeholder="Search by product title..." v-model="inputSearchText" debounce="250"></b-form-input>
                                <div class="text-center mt-2" v-if="(filteredInputProducts.length < $store.state.loadedStandardProducts.length || $store.state.loadedStandardProducts.length + $store.state.standardFeed.products.length < maximumProducts) && filteredInputProducts.length > 0">
                                    <b-button @click="addAll" variant="outline-dark" :disabled="filteredInputProducts.length + $store.state.standardFeed.products.length > maximumProducts">Add All</b-button>
                                </div>
                                <div class="unselectedProductsCont mt-3">
                                    <b-row>
                                        <b-col sm="4" v-for="(product, index) in filteredInputProducts.slice(0, inputLoadAmount)" :key="index">
                                            <ProductComponent :product="product" @addProduct="addProduct" :class="index < 3 ? '' : 'mt-4'" :selected="false" />
                                        </b-col>
                                    </b-row>
                                    
                                    <div class="mt-4 mb-3 text-center">
                                        <b-button @click="inputLoadAmount -= 30" v-if="inputLoadAmount > 30 && filteredInputProducts.length > 30" variant="outline-danger" class="mr-2">Load Less</b-button>
                                        <b-button @click="inputLoadAmount += 30" v-if="filteredInputProducts.length > inputLoadAmount" variant="outline-dark">Load More</b-button>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>
                    </b-card-body>
                </b-card>
            </b-container>
        </b-col>

        <b-modal v-model="addFeedModal" centered hide-footer>
            <template #modal-title>Add Google Merchant Center Feed</template>
            <div>
                <GMCInputForm @feedAdded="addFeedModal = false;" />
            </div>
        </b-modal>

        <b-modal v-model="addProductModal" centered hide-footer>
            <template #modal-title>Add a Product</template>

            <div>
                <ProductAddForm @productAdded="addProductModal = false;" />
            </div>
        </b-modal>
    </b-row>
</template>

<script>
import ProductComponent from '@/components/Standard/ProductComponent.vue'
import ProductAddForm from '@/components/Standard/ProductAddForm.vue'
import GMCInputForm from '@/components/GoogleMerchantCenter/GMCInputForm.vue';

export default {
    name: 'StandardBuild',
    components: { ProductComponent, ProductAddForm, GMCInputForm },
    data() {
        return {
            inputSearchText: '',
            selectedSearchText: '',
            inputLoadAmount: 30,
            selectedLoadAmount: 30,
            maximumProducts: 1000,

            // Filters:
            selectedSale: false,
            availableSale: false,
            selectedNoSale: false,
            selectedBrands: [],
            availableBrands: [],
            brandSearchText: '',
            selectedGoogleProductCategories: [],
            availableGoogleProductCategories: [],
            googleProductCategorySearchText: '',
            selectedProductTypes: [],
            availableProductTypes: [],
            productTypeSearchText: '',
            selectedAvailability: [],
            availableAvailability: [],
            selectedCondition: [],
            availableCondition: [],

            // Utility:
            resetFilters: true,

            // Bootstrap:
            inputCollapse: true,
            selectedCollapse: false,
            addFeedModal: false,
            addProductModal: false,
        }
    },

    created() {
        if (this.$store.state.standardFeed === null) {
            this.$router.replace("/feed/standard");
            return;
        }

        this.setFilters();
    },

    computed: {
        // Returns all input products after the various filters have been applied to them.
        filteredInputProducts() {
            let temp = [];

            if (this.inputSearchText) {
                temp = this.$store.state.loadedStandardProducts.filter(product => {
                    if (product.title) {
                        return product.title.toLowerCase().includes(this.inputSearchText.toLowerCase());
                    }

                    return null;
                });
            } else {
                temp = JSON.parse(JSON.stringify(this.$store.state.loadedStandardProducts));
            }

            if (this.selectedSale && !this.selectedNoSale) {
                temp = temp.filter(product => {
                    if (product.sale_price && product.sale_price < product.price) {
                        return true
                    }

                    return null;
                })
            } else if (this.selectedNoSale && !this.selectedSale) {
                temp = temp.filter(product => {
                    if (!product.sale_price || product.sale_price >= product.price) {
                        return true;
                    }

                    return null;
                })
            }

            if (this.selectedBrands.length > 0) {
                temp = temp.filter(product => {
                    if (product.brand) {
                        return this.selectedBrands.some(value => product.brand == value);
                    }

                    return null;
                })
            }

            if (this.selectedGoogleProductCategories.length > 0) {
                temp = temp.filter(product => {
                    if (product.google_product_category) {
                        return this.selectedGoogleProductCategories.some(value => product.google_product_category == value);
                    }

                    return null;
                })
            }

            if (this.selectedProductTypes.length > 0) {
                temp = temp.filter(product => {
                    if (product.product_type) {
                        return this.selectedProductTypes.some(value => product.product_type == value);
                    }

                    return null;
                })
            }

            if (this.selectedAvailability.length > 0) {
                temp = temp.filter(product => {
                    if (product.availability) {
                        return this.selectedAvailability.some(value => product.availability == value);
                    }

                    return null;
                })
            }

            if (this.selectedCondition.length > 0) {
                temp = temp.filter(product => {
                    if (product.condition) {
                        return this.selectedCondition.some(value => product.condition == value);
                    }

                    return null;
                })
            }

            return temp;
        },

        // Returns selected products filtered with the search text.
        filteredSelectedProducts() {
            if (this.selectedSearchText) {
                return this.$store.state.standardFeed.products.filter(product => {
                    return product.title.toLowerCase().includes(this.selectedSearchText.toLowerCase());
                });
            } else {
                return this.$store.state.standardFeed.products;
            }
        },

        // Returns available brand filters, filtered with the search text.
        filteredAvailableBrands() {
            if (this.brandSearchText) {
                return this.availableBrands.filter(brand => {
                    return brand.toLowerCase().includes(this.brandSearchText.toLowerCase());
                })
            } else {
                return this.availableBrands
            }
        },

        // Returns available google product category filters, filtered with the search text.
        filteredAvailableGoogleProductCategories() {
            if (this.googleProductCategorySearchText) {
                return this.availableGoogleProductCategories.filter(category => {
                    return category.toLowerCase().includes(this.googleProductCategorySearchText.toLowerCase());
                })
            } else {
                return this.availableGoogleProductCategories;
            }
        },

        // Returns available product type filters, filtered with the search text.
        filteredAvailableProductTypes() {
            if (this.productTypeSearchText) {
                return this.availableProductTypes.filter(type => {
                    return type.toLowerCase().includes(this.productTypeSearchText.toLowerCase());
                })
            } else {
                return this.availableProductTypes;
            }
        },

        // Returns length of input feed.
        inputFeedLength() {
            return this.$store.state.loadedStandardProducts.length;
        }
    },

    methods: {
        // Adds a product to the selected products, and removes from loaded.
        addProduct(id) {
            const index = this.$store.state.loadedStandardProducts.findIndex(x => x.id == id);
            this.$store.commit("addStandardProduct", this.$store.state.loadedStandardProducts[index]);
            this.$store.commit("removeStandardProductFromLoaded", index);
        },

        // Edits a selected product.
        editProduct(id, newProductData) {
            const index = this.$store.state.standardFeed.products.findIndex(x => x.id == id);

            // Set this index to be equal to the new product data.
            this.$store.commit("editStandardProduct", { index, newProductData });
        },

        // Removes a product from selected products, and adds to loaded.
        removeProduct(id) {
            const index = this.$store.state.standardFeed.products.findIndex(x => x.id == id);

            // Check if brand included in available brands, and push in if allowed.
            // Because we're doing this we dont need the watcher function to run, hence we set resetFilters to false.
            this.resetFilters = false;

            if (!this.availableSale && this.$store.state.standardFeed.products[index].sale_price) {
                this.availableSale = true;
            }

            if (this.$store.state.standardFeed.products[index].brand && !this.availableBrands.includes(this.$store.state.standardFeed.products[index].brand) && this.$store.state.standardFeed.products[index].brand.trim !== "") {
                this.availableBrands.push(this.$store.state.standardFeed.products[index].brand)
            }

            // Add to input products.
            this.$store.commit("addStandardProductToLoaded", this.$store.state.standardFeed.products[index]);
            this.$store.commit("removeStandardProduct", index);
        },

        // Adds all filtered loaded products.
        addAll() {
            // Add all products currently in filtered products.
            let idsToAdd = this.filteredInputProducts.map(x => x.id);
            let indicesToAdd = [];

            for (let i = 0; i < this.$store.state.loadedStandardProducts.length; i ++) {
                if (idsToAdd.includes(this.$store.state.loadedStandardProducts[i].id)) {
                    // Remove this ID from the idsToAdd array as we have now added it.
                    const index = idsToAdd.findIndex(x => x == this.$store.state.loadedStandardProducts[i].id);
                    idsToAdd.splice(index, 1);

                    // Add to our feed and remove from loaded.
                    // this.$store.commit("addStandardProduct", this.$store.state.loadedStandardProducts[i]);
                    // this.$store.commit("removeStandardProductFromLoaded", i);
                    indicesToAdd.push(i);
                    console.log("ADD:", this.$store.state.loadedStandardProducts[i])
                    console.log("REMOVE", i);

                    // Decrement i so we check this index again (as it no longer is the same product).
                    i --;
                }

                if (idsToAdd.length === 0) {
                    break;
                }
            }

            this.$store.commit("addMultipleStandardProducts", indicesToAdd);
        },

        nextStepThree() {
            this.$router.push("/feed/standard/review");
        },

        // Resets available filters after a product has been removed.
        setFilters() {
            this.availableBrands = [];
            this.availableAvailability = [];
            this.availableCondition = [];

            // Set the available filters
            this.$store.state.loadedStandardProducts.forEach(item => {
                // Brand
                if (item.brand && !this.availableBrands.includes(item.brand) && item.brand.trim() !== "") {
                    this.availableBrands.push(item.brand);
                }

                // Google Product Category
                if (item.google_product_category && !this.availableGoogleProductCategories.includes(item.google_product_category) && item.google_product_category.trim() !== "") {
                    this.availableGoogleProductCategories.push(item.google_product_category);
                }

                // Product Type
                if (item.product_type && !this.availableProductTypes.includes(item.product_type) && item.product_type.trim() !== "") {
                    this.availableProductTypes.push(item.product_type);
                }

                // Availability
                if (item.availability && !this.availableAvailability.includes(item.availability) && item.availability.trim() !== "") {
                    this.availableAvailability.push(item.availability);
                }

                // Condition
                if (item.condition && !this.availableCondition.includes(item.condition) && item.condition.trim() !== "") {
                    this.availableCondition.push(item.condition);
                }

                // Sale
                if (!this.availableSale && item.sale_price && item.sale_price < item.price) {
                    this.availableSale = true;
                }
            })
        }
    },

    watch: {
        inputFeedLength(n, o) {
            if (n > o && this.resetFilters) {
                this.setFilters();
            } else if (n > 0 && !this.resetFilters) {
                this.resetFilters = true;
            }
        }
    }
}
</script>

<style scoped>
    .hover-icon:hover {
        cursor: pointer;
    }

    /* .unselectedProductsCont, */
    .selectedProductsCont {
        max-height: 1200px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .googleProductCategoriesCont,
    .productTypesCont,
    .brandsCont {
        max-height: 600px;
        overflow-y: scroll;
    }

    .align-items {
        align-items: center !important;
    }

    .add-products-dropdown-icon {
        height: 24px !important;
        width: 24px !important;
    }

    .add-products-dropdown-menu {
        min-width: unset !important;
    }
</style>

<style>
    #additionalFeedUpload .custom-file-label {
        visibility: hidden !important;
    }

    #additionalFeedUpload .custom-file-label:hover {
        cursor: pointer;
    }

    #additionalFeedUpload .custom-file-label:hover::after {
        background-color: #343a40 !important;
        color: white !important;
    }

    #additionalFeedUpload .custom-file-label::after {
        left: calc(50% - 55px) !important;
        right: unset !important;
        border: inherit !important;
        border-color: #343a40 !important;
        border-radius: 0.25rem !important;
        visibility: visible !important;
        background: none !important;
        color: #343a40 !important;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
    }
</style>