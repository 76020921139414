import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import { auth } from './firebase'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.config.productionTip = false

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')

auth.onAuthStateChanged(async user => {
    console.log("AUTH STATE CHANGED", user);
    store.dispatch("fetchUser", user);
})