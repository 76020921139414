<template>
    <!-- 
        Standard Home points to the URL: https://feedbuilder.newsconnect.com.au/feed/standard
     -->
    <b-row>
        <b-col lg="3" cols="0">

        </b-col>
        <b-col lg="6" cols="12">
            <b-container>
                <b-card no-body>
                    <b-card-body>
                        <b-card-title>Create or Edit Standard Feed</b-card-title>
                        <div class="stepOneCont">
                            <b-card-sub-title>First step, create feed or edit existing by inputting feed ID:</b-card-sub-title>

                            <div>
                                <b-form class="mt-2 p-2" @submit.prevent="editFeed">
                                    <b-form-group label="Input Feed ID:">
                                        <b-input type="text" v-model="feedIdInput" placeholder="Unique feed identifier" :disabled="isLoading" />
                                    </b-form-group>

                                    <b-form-group label="Feed password:">
                                        <b-input type="password" v-model="feedPasswordInput" placeholder="Feed password" :disabled="isLoading" />
                                    </b-form-group>

                                    <div class="buttons d-flex">
                                        <div class="ml-auto">
                                            <b-button variant="outline-dark" type="submit" :disabled="feedIdInput.length == 0 || isLoading || feedPasswordInput.length == 0">
                                                <span v-if="!isLoading">Edit</span>
                                                <span v-else><b-spinner small /></span>
                                            </b-button>
                                            <b-button variant="outline-success" class="ml-2" :disabled="feedIdInput.length > 0" @click="createModalActive = true">Create</b-button>
                                        </div>
                                    </div>
                                </b-form>
                            </div>
                            <div>
                                <b-alert variant="danger" fade :show="alertCountdown" dismissible @dismissed="alertCountdown = 0" @dismiss-count-down="alertCountdownChanged">
                                    Error pulling feed. Please ensure you have input the correct feed ID and password.
                                </b-alert>
                            </div>
                        </div>
                    </b-card-body>
                </b-card>
            </b-container>
        </b-col>
        <b-col lg="3" cols="0">

        </b-col>

        <b-modal v-model="createModalActive" centered title="Create Feed" hide-footer>
            <div>
                <b-form @submit.prevent="createFeed">
                    <b-form-group description="Feed ID must be unique, contain at least 8 characters, and must contain only letters and hyphens">
                        <b-input-group>
                            <label for="feedIdInput" class="d-block w-100">
                                <div class="d-flex">
                                    <div>Feed ID</div>
                                    <div class="ml-auto"><b-link href="#" class="font-small" @click="generateId(16)">Generate</b-link></div>
                                </div>
                            </label>
                            <b-form-input id="feedIdInput" v-model="feedInput.uid" placeholder="Enter feed ID" required debounce="250" />
                            <b-input-group-append>
                                <b-button v-if="!checkingUniqueId && feedIdValid" disabled variant="success">
                                    <b-icon-check />
                                </b-button>
                                <b-button v-else-if="!checkingUniqueId && !feedIdValid" disabled variant="danger">
                                    <b-icon-x />
                                </b-button>
                                <b-button v-else disabled>
                                    <b-spinner small />
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>

                    <b-form-group label="Feed password" description="Password must be at least 6 characters">
                        <b-input-group>
                            <b-form-input v-model="feedInput.password" type="password" placeholder="Enter password" required />

                            <b-input-group-append>
                                <b-button v-if="passwordValid" disabled variant="success">
                                    <b-icon-check />
                                </b-button>
                                <b-button v-else disabled variant="danger">
                                    <b-icon-x />
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>

                    <b-form-group label="Confirm password">
                        <b-input-group>
                            <b-form-input v-model="feedInput.confPassword" type="password" placeholder="Confirm password" required />

                            <b-input-group-append>
                                <b-button v-if="confPasswordValid" disabled variant="success">
                                    <b-icon-check />
                                </b-button>
                                <b-button v-else disabled variant="danger">
                                    <b-icon-x />
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>

                    <div class="text-center">
                        <b-button variant="outline-success" type="submit" :disabled="!feedValid" @click="createFeed">Create</b-button>
                    </div>
                </b-form>
            </div>
        </b-modal>
    </b-row>
</template>

<script>
import { functions } from "@/firebase"
import parser from 'xml2js'

export default {
    name: "StandardAPIHome",
    data() {
        return {
            feedIdInput: '',
            feedPasswordInput: '',
            feedInput: {
                uid: '',
                password: '',
                confPassword: ''
            },
            result: '',
            isLoading: false,

            checkingUniqueId: false,
            feedIdUnique: false,

            // Bootstrap
            createModalActive: false,
            dismissSecs: 10,
            alertCountdown: 0,
        }
    },

    mounted() {
        console.log(this.createModalActive);
        console.log(this.$route.query);

        if (this.$route.query.ref === "newmanualfeed" || this.$route.query.ref === "newgmcfeed") {
            this.createModalActive = true;
            console.log(this.createModalActive);
        }
    },

    computed: {
        // Function that returns validity based on if Feed ID is valid (doesn't check uniqueness here).
        feedIdValidString() {
            if (!/^[a-zA-Z-]+$/.test(this.feedInput.uid)) {
                return false;
            }

            if (this.feedInput.uid.length < 8) {
                return false;
            }

            return true;
        },

        // Function that returns validity based on if Feed ID is valid and unique.
        feedIdValid() {
            if (!this.feedIdValidString) {
                return false;
            }

            if (!this.feedIdUnique) {
                return false;
            }

            return true;
        },

        // Function that returns validity based on if password is valid.
        passwordValid() {
            if (this.feedInput.password.length < 6) {
                return false;
            }

            return true;
        },

        // Function that returns validity based on if password matches confirm password
        confPasswordValid() {
            if (!this.passwordValid || this.feedInput.password !== this.feedInput.confPassword) {
                return false;
            }

            return true;
        },

        // Function that returns validity based on if feed ID is valid, and passwords are valid.
        feedValid() {
            if (!this.feedIdValid || !this.passwordValid || !this.confPasswordValid) {
                return false;
            }

            return true;
        },

        // Returns UID so we can watch this in the watch method.
        feedIdWatchHandler() {
            return this.feedInput.uid;
        }
    },

    methods: {
        // Downloads feed from Firebase with the password. If so it sets our current feed to that and progresses to Standard Build.
        async editFeed() {
            this.isLoading = true;
            const getAPIFeed = functions.httpsCallable("getAPIFeed");
            const result = await getAPIFeed({ feedId: this.feedIdInput, password: this.feedPasswordInput, type: "standardAPI" }).catch(() => {
                return null;
            });

            const callCustomAPI = functions.httpsCallable('callCustomAPI');
            const APIResult = await callCustomAPI({ url: result.data.feed.url }).catch(() => {
                return null;
            });

            console.log(APIResult);

            const xmlResult = APIResult.data;
            console.log(xmlResult);
            const data = await parser.parseStringPromise(xmlResult, {
                explicitArray: false,
                ignoreAttrs: true,
                tagNameProcessors: [(name) => {
                    if(name.substring(0, 2) == "g:") {
                        return name.substring(2, name.length);
                    } else {
                        return name;
                    }
                }],
                valueProcessors: [(value, name) => {
                    // If any value surrounded by quotation marks, remove them.
                    if(value && value.charAt(0) == "\"" && value.charAt(value.length - 1) == "\""){
                        value = value.substring(1, value.length - 1);
                    }
                    // Remove AUD from price and sale_price
                    if(value && (name == "price" || name == "sale_price")){
                        if (value.substring(value.length - 4, value.length) == " AUD") {
                            value = value.substring(0, value.length - 4);
                        } else if (value.substring(value.length - 3, value.length) == "AUD") {
                            value = value.substring(0, value.length - 3);
                        }
                    }
                    if(value && name == "image_link"){
                        if(value.substring(0,5) == "http:"){
                            value = "https" + value.substring(4, value.length);
                        }
                    }
                    return value;
                }]
            });

            let feed = data.rss.channel.item;
            
            for (let i = 0; i < feed.length; i ++) {
                const temp = feed[i];

                feed[i] = {
                    id: temp.id,
                    title: temp.title,
                    link: temp.link,
                    price: temp.price,
                    image_link: temp.image_link
                }

                if (temp.sale_price) {
                    feed[i].sale_price = temp.sale_price;
                }

                if (temp.brand) {
                    feed[i].brand = temp.brand;
                }

                if (temp.google_product_category) {
                    feed[i].google_product_category = temp.google_product_category;
                }   
                
                if (temp.product_type) {
                    feed[i].product_type = temp.product_type;
                }

                if (temp.availability) {
                    feed[i].availability = temp.availability;
                }

                if (temp.condition) {
                    feed[i].condition = temp.condition;
                }
            } 
            console.log(feed);
            if (result && result.data && result.data.success && APIResult.data) {
                this.$store.commit("addStandardFeedToLoaded", feed);
                let feedData = result.data.feed;
                feedData.password = this.feedPasswordInput;
                feedData.isCreating = false;
                console.log(feedData);
                this.$store.commit("setStandardFeed", feedData);
                this.$router.push("/feed/standardAPI/build");
                this.isLoading = false;
            } else {
                console.error(result);
                this.alertCountdown = this.dismissSecs;
                this.isLoading = false;
            }

            console.log("EDIT.");
        },

        // Creates a new feed with inputted UID and Password.
        createFeed() {
            this.$store.commit("setStandardFeed", {
                type: 'standardAPI',
                uid: this.feedInput.uid,
                password: this.feedInput.password,
                products: [],
                isCreating: true,
                url: ''
            });

            this.$router.push({ name: "StandardAPINew", query: this.$route.query });
        },

        // Generates a random ID.
        generateId(n) {
            let randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
            let id = '';

            for (let i = 0; i < n; i++) {
                id += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
            }

            this.feedInput.uid = id;
        },

        // Used for the error countdown timer.
        alertCountdownChanged(alertCountdown) {
            this.alertCountdown = alertCountdown;
        }
    },

    watch: {
        // Checks if the Feed ID is unique when the Feed ID changes.
        async feedIdWatchHandler() {
            if (this.feedIdValidString) {
                this.checkingUniqueId = true;

                const checkUniqueFeedUID = functions.httpsCallable("checkUniqueFeedUID");
                const result = await checkUniqueFeedUID({ feedId: this.feedInput.uid });

                console.log("UNIQUE CHECK RESULT:", result);

                this.feedIdUnique = result.data.uidUnique;
                this.checkingUniqueId = false;
            } else {
                this.feedIdUnique = false;
            }
        }
    }
}
</script>

<style scoped>
    .font-small {
        font-size: 12px;
    }
</style>